const pitBullBushidoMetaData = {
    "629": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #629",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "629",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-4.png",
            "scheme": "https"
        }
    },
    "1825": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1825",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1825",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-29.png",
            "scheme": "https"
        }
    },
    "107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #107",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "107",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-46.png",
            "scheme": "https"
        }
    },
    "2099": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2099",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "2099",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-4.png",
            "scheme": "https"
        }
    },
    "372": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #372",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "372",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-41.png",
            "scheme": "https"
        }
    },
    "577": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #577",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "577",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-41.png",
            "scheme": "https"
        }
    },
    "2205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2205",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "2205",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-15.png",
            "scheme": "https"
        }
    },
    "1791": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1791",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1791",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-46.png",
            "scheme": "https"
        }
    },
    "71": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #71",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "71",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-40.png",
            "scheme": "https"
        }
    },
    "1300": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1300",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1300",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-45.png",
            "scheme": "https"
        }
    },
    "694": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #694",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "694",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-2.png",
            "scheme": "https"
        }
    },
    "1084": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1084",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1084",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-33.png",
            "scheme": "https"
        }
    },
    "1230": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1230",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1230",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-47.png",
            "scheme": "https"
        }
    },
    "2198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2198",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2198",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-45.png",
            "scheme": "https"
        }
    },
    "1114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1114",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1114",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-45.png",
            "scheme": "https"
        }
    },
    "1064": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1064",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1064",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-22.png",
            "scheme": "https"
        }
    },
    "206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #206",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "206",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-16.png",
            "scheme": "https"
        }
    },
    "1451": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1451",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1451",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-44.png",
            "scheme": "https"
        }
    },
    "749": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #749",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "749",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-7.png",
            "scheme": "https"
        }
    },
    "1823": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1823",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1823",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-1.png",
            "scheme": "https"
        }
    },
    "90": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #90",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "90",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-7.png",
            "scheme": "https"
        }
    },
    "953": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #953",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "953",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-32.png",
            "scheme": "https"
        }
    },
    "1045": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1045",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1045",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-12.png",
            "scheme": "https"
        }
    },
    "1975": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1975",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1975",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-37.png",
            "scheme": "https"
        }
    },
    "466": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #466",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "466",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-33.png",
            "scheme": "https"
        }
    },
    "29": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #29",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "29",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-11.png",
            "scheme": "https"
        }
    },
    "2014": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2014",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "2014",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-33.png",
            "scheme": "https"
        }
    },
    "1983": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1983",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1983",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-16.png",
            "scheme": "https"
        }
    },
    "1048": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1048",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1048",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-17.png",
            "scheme": "https"
        }
    },
    "18": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #18",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "18",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-46.png",
            "scheme": "https"
        }
    },
    "1138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1138",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1138",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-34.png",
            "scheme": "https"
        }
    },
    "179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #179",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "179",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-35.png",
            "scheme": "https"
        }
    },
    "240": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #240",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "240",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-12.png",
            "scheme": "https"
        }
    },
    "882": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #882",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "882",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-43.png",
            "scheme": "https"
        }
    },
    "646": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #646",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "646",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-15.png",
            "scheme": "https"
        }
    },
    "269": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #269",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "269",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-14.png",
            "scheme": "https"
        }
    },
    "1876": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1876",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1876",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-38.png",
            "scheme": "https"
        }
    },
    "1457": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1457",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1457",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-8.png",
            "scheme": "https"
        }
    },
    "1878": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1878",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1878",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-4.png",
            "scheme": "https"
        }
    },
    "569": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #569",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "569",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-43.png",
            "scheme": "https"
        }
    },
    "1922": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1922",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1922",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-22.png",
            "scheme": "https"
        }
    },
    "621": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #621",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "621",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-21.png",
            "scheme": "https"
        }
    },
    "399": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #399",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "399",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-23.png",
            "scheme": "https"
        }
    },
    "1111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1111",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1111",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-19.png",
            "scheme": "https"
        }
    },
    "615": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #615",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "615",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-45.png",
            "scheme": "https"
        }
    },
    "35": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #35",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "35",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-28.png",
            "scheme": "https"
        }
    },
    "677": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #677",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "677",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-2.png",
            "scheme": "https"
        }
    },
    "1587": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1587",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1587",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-46.png",
            "scheme": "https"
        }
    },
    "947": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #947",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "947",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-7.png",
            "scheme": "https"
        }
    },
    "1855": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1855",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1855",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-17.png",
            "scheme": "https"
        }
    },
    "1970": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1970",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1970",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-16.png",
            "scheme": "https"
        }
    },
    "199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #199",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "199",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-12.png",
            "scheme": "https"
        }
    },
    "1782": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1782",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1782",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-9.png",
            "scheme": "https"
        }
    },
    "697": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #697",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "697",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-2.png",
            "scheme": "https"
        }
    },
    "1951": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1951",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1951",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-4.png",
            "scheme": "https"
        }
    },
    "245": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #245",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "245",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-40.png",
            "scheme": "https"
        }
    },
    "1081": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1081",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1081",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-31.png",
            "scheme": "https"
        }
    },
    "1577": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1577",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1577",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-18.png",
            "scheme": "https"
        }
    },
    "356": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #356",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "356",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-3.png",
            "scheme": "https"
        }
    },
    "1849": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1849",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1849",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-32.png",
            "scheme": "https"
        }
    },
    "682": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #682",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "682",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-33.png",
            "scheme": "https"
        }
    },
    "298": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #298",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "298",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-21.png",
            "scheme": "https"
        }
    },
    "1811": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1811",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1811",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-25.png",
            "scheme": "https"
        }
    },
    "979": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #979",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "979",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-25.png",
            "scheme": "https"
        }
    },
    "1494": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1494",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1494",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-9.png",
            "scheme": "https"
        }
    },
    "1461": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1461",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1461",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-11.png",
            "scheme": "https"
        }
    },
    "2206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2206",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2206",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-13.png",
            "scheme": "https"
        }
    },
    "1422": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1422",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1422",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-31.png",
            "scheme": "https"
        }
    },
    "1124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1124",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1124",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-4.png",
            "scheme": "https"
        }
    },
    "1261": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1261",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1261",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-19.png",
            "scheme": "https"
        }
    },
    "464": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #464",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "464",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-47.png",
            "scheme": "https"
        }
    },
    "1853": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1853",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1853",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-10.png",
            "scheme": "https"
        }
    },
    "1592": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1592",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1592",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-36.png",
            "scheme": "https"
        }
    },
    "2142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2142",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "2142",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-35.png",
            "scheme": "https"
        }
    },
    "1306": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1306",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1306",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-5.png",
            "scheme": "https"
        }
    },
    "547": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #547",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "547",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-12.png",
            "scheme": "https"
        }
    },
    "193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #193",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "193",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-11.png",
            "scheme": "https"
        }
    },
    "1400": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1400",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1400",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-20.png",
            "scheme": "https"
        }
    },
    "1808": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1808",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1808",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-35.png",
            "scheme": "https"
        }
    },
    "945": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #945",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "945",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-14.png",
            "scheme": "https"
        }
    },
    "1765": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1765",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1765",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-40.png",
            "scheme": "https"
        }
    },
    "347": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #347",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "347",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-23.png",
            "scheme": "https"
        }
    },
    "61": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #61",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "61",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-1.png",
            "scheme": "https"
        }
    },
    "1750": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1750",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1750",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-24.png",
            "scheme": "https"
        }
    },
    "1149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1149",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1149",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-47.png",
            "scheme": "https"
        }
    },
    "1563": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1563",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1563",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-36.png",
            "scheme": "https"
        }
    },
    "1115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1115",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1115",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-36.png",
            "scheme": "https"
        }
    },
    "930": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #930",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "930",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-32.png",
            "scheme": "https"
        }
    },
    "1166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1166",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1166",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-27.png",
            "scheme": "https"
        }
    },
    "860": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #860",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "860",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-19.png",
            "scheme": "https"
        }
    },
    "2055": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2055",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "2055",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-27.png",
            "scheme": "https"
        }
    },
    "1267": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1267",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1267",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-18.png",
            "scheme": "https"
        }
    },
    "2167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2167",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "2167",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-32.png",
            "scheme": "https"
        }
    },
    "1507": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1507",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1507",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-22.png",
            "scheme": "https"
        }
    },
    "1453": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1453",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1453",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-29.png",
            "scheme": "https"
        }
    },
    "969": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #969",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "969",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-46.png",
            "scheme": "https"
        }
    },
    "901": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #901",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "901",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-47.png",
            "scheme": "https"
        }
    },
    "111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #111",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "111",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-13.png",
            "scheme": "https"
        }
    },
    "825": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #825",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "825",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-24.png",
            "scheme": "https"
        }
    },
    "989": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #989",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "989",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-25.png",
            "scheme": "https"
        }
    },
    "385": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #385",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "385",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-36.png",
            "scheme": "https"
        }
    },
    "584": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #584",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "584",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-12.png",
            "scheme": "https"
        }
    },
    "1785": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1785",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1785",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-29.png",
            "scheme": "https"
        }
    },
    "2172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2172",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "2172",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-14.png",
            "scheme": "https"
        }
    },
    "402": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #402",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "402",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-24.png",
            "scheme": "https"
        }
    },
    "1764": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1764",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1764",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-21.png",
            "scheme": "https"
        }
    },
    "788": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #788",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "788",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-30.png",
            "scheme": "https"
        }
    },
    "1943": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1943",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1943",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-34.png",
            "scheme": "https"
        }
    },
    "589": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #589",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "589",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-2.png",
            "scheme": "https"
        }
    },
    "983": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #983",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "983",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-31.png",
            "scheme": "https"
        }
    },
    "1345": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1345",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1345",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-29.png",
            "scheme": "https"
        }
    },
    "15": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #15",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "15",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-21.png",
            "scheme": "https"
        }
    },
    "1351": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1351",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1351",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-4.png",
            "scheme": "https"
        }
    },
    "138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #138",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "138",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-42.png",
            "scheme": "https"
        }
    },
    "1654": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1654",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1654",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-29.png",
            "scheme": "https"
        }
    },
    "1161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1161",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1161",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-24.png",
            "scheme": "https"
        }
    },
    "1904": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1904",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1904",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-44.png",
            "scheme": "https"
        }
    },
    "717": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #717",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "717",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-26.png",
            "scheme": "https"
        }
    },
    "87": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #87",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "87",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-20.png",
            "scheme": "https"
        }
    },
    "21": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #21",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "21",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-9.png",
            "scheme": "https"
        }
    },
    "1329": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1329",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1329",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-35.png",
            "scheme": "https"
        }
    },
    "304": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #304",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "304",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-2.png",
            "scheme": "https"
        }
    },
    "836": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #836",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "836",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-17.png",
            "scheme": "https"
        }
    },
    "225": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #225",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "225",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-43.png",
            "scheme": "https"
        }
    },
    "996": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #996",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "996",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-7.png",
            "scheme": "https"
        }
    },
    "1657": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1657",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1657",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-44.png",
            "scheme": "https"
        }
    },
    "331": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #331",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "331",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-5.png",
            "scheme": "https"
        }
    },
    "391": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #391",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "391",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-9.png",
            "scheme": "https"
        }
    },
    "755": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #755",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "755",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-31.png",
            "scheme": "https"
        }
    },
    "985": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #985",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "985",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-35.png",
            "scheme": "https"
        }
    },
    "150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #150",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "150",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-13.png",
            "scheme": "https"
        }
    },
    "1037": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1037",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1037",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-26.png",
            "scheme": "https"
        }
    },
    "1936": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1936",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1936",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-33.png",
            "scheme": "https"
        }
    },
    "2078": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2078",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2078",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-10.png",
            "scheme": "https"
        }
    },
    "406": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #406",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "406",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-13.png",
            "scheme": "https"
        }
    },
    "550": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #550",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "550",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-11.png",
            "scheme": "https"
        }
    },
    "1017": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1017",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1017",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-17.png",
            "scheme": "https"
        }
    },
    "1664": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1664",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1664",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-11.png",
            "scheme": "https"
        }
    },
    "1884": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1884",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1884",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-43.png",
            "scheme": "https"
        }
    },
    "191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #191",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "191",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-1.png",
            "scheme": "https"
        }
    },
    "1553": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1553",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1553",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-22.png",
            "scheme": "https"
        }
    },
    "266": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #266",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "266",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-33.png",
            "scheme": "https"
        }
    },
    "1946": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1946",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1946",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-32.png",
            "scheme": "https"
        }
    },
    "361": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #361",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "361",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-3.png",
            "scheme": "https"
        }
    },
    "44": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #44",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "44",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-47.png",
            "scheme": "https"
        }
    },
    "1060": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1060",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1060",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-33.png",
            "scheme": "https"
        }
    },
    "2052": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2052",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2052",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-44.png",
            "scheme": "https"
        }
    },
    "1077": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1077",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1077",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-3.png",
            "scheme": "https"
        }
    },
    "1758": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1758",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1758",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-42.png",
            "scheme": "https"
        }
    },
    "485": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #485",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "485",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-35.png",
            "scheme": "https"
        }
    },
    "1426": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1426",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1426",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-16.png",
            "scheme": "https"
        }
    },
    "351": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #351",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "351",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-3.png",
            "scheme": "https"
        }
    },
    "1640": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1640",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1640",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-20.png",
            "scheme": "https"
        }
    },
    "1996": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1996",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1996",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-32.png",
            "scheme": "https"
        }
    },
    "2072": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2072",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "2072",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-44.png",
            "scheme": "https"
        }
    },
    "1917": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1917",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1917",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-43.png",
            "scheme": "https"
        }
    },
    "1483": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1483",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1483",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-47.png",
            "scheme": "https"
        }
    },
    "1584": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1584",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1584",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-14.png",
            "scheme": "https"
        }
    },
    "2193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2193",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "2193",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-26.png",
            "scheme": "https"
        }
    },
    "1236": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1236",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1236",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-2.png",
            "scheme": "https"
        }
    },
    "238": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #238",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "238",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-36.png",
            "scheme": "https"
        }
    },
    "381": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #381",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "381",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-14.png",
            "scheme": "https"
        }
    },
    "1816": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1816",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1816",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-18.png",
            "scheme": "https"
        }
    },
    "501": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #501",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "501",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-8.png",
            "scheme": "https"
        }
    },
    "680": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #680",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "680",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-16.png",
            "scheme": "https"
        }
    },
    "827": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #827",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "827",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-20.png",
            "scheme": "https"
        }
    },
    "1660": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1660",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1660",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-28.png",
            "scheme": "https"
        }
    },
    "2187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2187",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "2187",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-37.png",
            "scheme": "https"
        }
    },
    "1172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1172",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1172",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-8.png",
            "scheme": "https"
        }
    },
    "952": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #952",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "952",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-34.png",
            "scheme": "https"
        }
    },
    "1576": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1576",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1576",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-38.png",
            "scheme": "https"
        }
    },
    "1591": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1591",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1591",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-7.png",
            "scheme": "https"
        }
    },
    "340": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #340",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "340",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-46.png",
            "scheme": "https"
        }
    },
    "1369": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1369",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1369",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-30.png",
            "scheme": "https"
        }
    },
    "154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #154",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "154",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-18.png",
            "scheme": "https"
        }
    },
    "2114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2114",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2114",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-41.png",
            "scheme": "https"
        }
    },
    "1": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-5.png",
            "scheme": "https"
        }
    },
    "718": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #718",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "718",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-18.png",
            "scheme": "https"
        }
    },
    "1472": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1472",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1472",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-9.png",
            "scheme": "https"
        }
    },
    "53": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #53",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "53",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-20.png",
            "scheme": "https"
        }
    },
    "1286": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1286",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1286",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-2.png",
            "scheme": "https"
        }
    },
    "1702": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1702",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1702",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-14.png",
            "scheme": "https"
        }
    },
    "1762": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1762",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1762",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-44.png",
            "scheme": "https"
        }
    },
    "1191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1191",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1191",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-23.png",
            "scheme": "https"
        }
    },
    "723": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #723",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "723",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-20.png",
            "scheme": "https"
        }
    },
    "388": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #388",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "388",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-27.png",
            "scheme": "https"
        }
    },
    "772": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #772",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "772",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-44.png",
            "scheme": "https"
        }
    },
    "50": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #50",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "50",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-42.png",
            "scheme": "https"
        }
    },
    "1080": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1080",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1080",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-43.png",
            "scheme": "https"
        }
    },
    "955": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #955",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "955",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-4.png",
            "scheme": "https"
        }
    },
    "2129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2129",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "2129",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-18.png",
            "scheme": "https"
        }
    },
    "1239": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1239",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1239",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-9.png",
            "scheme": "https"
        }
    },
    "190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #190",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "190",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-46.png",
            "scheme": "https"
        }
    },
    "1497": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1497",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1497",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-47.png",
            "scheme": "https"
        }
    },
    "826": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #826",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "826",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-1.png",
            "scheme": "https"
        }
    },
    "674": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #674",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "674",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-31.png",
            "scheme": "https"
        }
    },
    "1838": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1838",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1838",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-45.png",
            "scheme": "https"
        }
    },
    "700": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #700",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "700",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-6.png",
            "scheme": "https"
        }
    },
    "1182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1182",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1182",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-35.png",
            "scheme": "https"
        }
    },
    "1779": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1779",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1779",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-20.png",
            "scheme": "https"
        }
    },
    "1688": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1688",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1688",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-18.png",
            "scheme": "https"
        }
    },
    "1802": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1802",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1802",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-10.png",
            "scheme": "https"
        }
    },
    "1480": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1480",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1480",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-9.png",
            "scheme": "https"
        }
    },
    "1110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1110",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1110",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-8.png",
            "scheme": "https"
        }
    },
    "1126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1126",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1126",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-1.png",
            "scheme": "https"
        }
    },
    "1429": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1429",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1429",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-44.png",
            "scheme": "https"
        }
    },
    "857": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #857",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "857",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-1.png",
            "scheme": "https"
        }
    },
    "1645": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1645",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1645",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-19.png",
            "scheme": "https"
        }
    },
    "1610": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1610",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1610",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-42.png",
            "scheme": "https"
        }
    },
    "116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #116",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "116",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-17.png",
            "scheme": "https"
        }
    },
    "102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #102",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "102",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-40.png",
            "scheme": "https"
        }
    },
    "2042": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2042",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "2042",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-17.png",
            "scheme": "https"
        }
    },
    "2005": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2005",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2005",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-17.png",
            "scheme": "https"
        }
    },
    "360": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #360",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "360",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-34.png",
            "scheme": "https"
        }
    },
    "782": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #782",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "782",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-12.png",
            "scheme": "https"
        }
    },
    "412": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #412",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "412",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-13.png",
            "scheme": "https"
        }
    },
    "1830": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1830",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1830",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-19.png",
            "scheme": "https"
        }
    },
    "1377": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1377",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1377",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-1.png",
            "scheme": "https"
        }
    },
    "630": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #630",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "630",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-6.png",
            "scheme": "https"
        }
    },
    "810": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #810",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "810",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-47.png",
            "scheme": "https"
        }
    },
    "712": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #712",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "712",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-5.png",
            "scheme": "https"
        }
    },
    "1492": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1492",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1492",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-10.png",
            "scheme": "https"
        }
    },
    "663": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #663",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "663",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-3.png",
            "scheme": "https"
        }
    },
    "840": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #840",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "840",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-18.png",
            "scheme": "https"
        }
    },
    "1325": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1325",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1325",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-23.png",
            "scheme": "https"
        }
    },
    "1309": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1309",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1309",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-17.png",
            "scheme": "https"
        }
    },
    "1229": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1229",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1229",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-13.png",
            "scheme": "https"
        }
    },
    "1361": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1361",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1361",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-32.png",
            "scheme": "https"
        }
    },
    "489": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #489",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "489",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-6.png",
            "scheme": "https"
        }
    },
    "912": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #912",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "912",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-43.png",
            "scheme": "https"
        }
    },
    "1385": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1385",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1385",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-26.png",
            "scheme": "https"
        }
    },
    "2131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2131",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "2131",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-9.png",
            "scheme": "https"
        }
    },
    "1893": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1893",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1893",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-36.png",
            "scheme": "https"
        }
    },
    "707": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #707",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "707",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-38.png",
            "scheme": "https"
        }
    },
    "1318": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1318",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1318",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-28.png",
            "scheme": "https"
        }
    },
    "1770": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1770",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1770",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-31.png",
            "scheme": "https"
        }
    },
    "1862": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1862",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1862",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-27.png",
            "scheme": "https"
        }
    },
    "1301": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1301",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1301",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-44.png",
            "scheme": "https"
        }
    },
    "366": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #366",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "366",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-31.png",
            "scheme": "https"
        }
    },
    "315": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #315",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "315",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-42.png",
            "scheme": "https"
        }
    },
    "1955": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1955",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1955",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-28.png",
            "scheme": "https"
        }
    },
    "198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #198",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "198",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-30.png",
            "scheme": "https"
        }
    },
    "1734": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1734",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1734",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-6.png",
            "scheme": "https"
        }
    },
    "1122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1122",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1122",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-23.png",
            "scheme": "https"
        }
    },
    "1473": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1473",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1473",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-29.png",
            "scheme": "https"
        }
    },
    "1675": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1675",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1675",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-14.png",
            "scheme": "https"
        }
    },
    "326": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #326",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "326",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-5.png",
            "scheme": "https"
        }
    },
    "2190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2190",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2190",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-10.png",
            "scheme": "https"
        }
    },
    "2138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2138",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "2138",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-13.png",
            "scheme": "https"
        }
    },
    "1447": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1447",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1447",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-2.png",
            "scheme": "https"
        }
    },
    "99": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #99",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "99",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-8.png",
            "scheme": "https"
        }
    },
    "1282": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1282",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1282",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-40.png",
            "scheme": "https"
        }
    },
    "1137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1137",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1137",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-46.png",
            "scheme": "https"
        }
    },
    "1962": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1962",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1962",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-10.png",
            "scheme": "https"
        }
    },
    "1850": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1850",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1850",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-19.png",
            "scheme": "https"
        }
    },
    "1903": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1903",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1903",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-34.png",
            "scheme": "https"
        }
    },
    "2170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2170",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "2170",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-33.png",
            "scheme": "https"
        }
    },
    "1270": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1270",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1270",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-47.png",
            "scheme": "https"
        }
    },
    "1242": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1242",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1242",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-23.png",
            "scheme": "https"
        }
    },
    "1662": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1662",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1662",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-9.png",
            "scheme": "https"
        }
    },
    "846": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #846",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "846",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-6.png",
            "scheme": "https"
        }
    },
    "1004": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1004",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1004",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-29.png",
            "scheme": "https"
        }
    },
    "867": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #867",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "867",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-16.png",
            "scheme": "https"
        }
    },
    "1939": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1939",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1939",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-27.png",
            "scheme": "https"
        }
    },
    "1022": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1022",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1022",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-24.png",
            "scheme": "https"
        }
    },
    "440": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #440",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "440",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-31.png",
            "scheme": "https"
        }
    },
    "2171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2171",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "2171",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-2.png",
            "scheme": "https"
        }
    },
    "1608": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1608",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1608",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-13.png",
            "scheme": "https"
        }
    },
    "528": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #528",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "528",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-12.png",
            "scheme": "https"
        }
    },
    "140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #140",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "140",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-4.png",
            "scheme": "https"
        }
    },
    "661": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #661",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "661",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-45.png",
            "scheme": "https"
        }
    },
    "1368": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1368",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1368",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-14.png",
            "scheme": "https"
        }
    },
    "194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #194",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "194",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-15.png",
            "scheme": "https"
        }
    },
    "790": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #790",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "790",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-12.png",
            "scheme": "https"
        }
    },
    "1969": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1969",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1969",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-13.png",
            "scheme": "https"
        }
    },
    "575": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #575",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "575",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-27.png",
            "scheme": "https"
        }
    },
    "2040": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2040",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "2040",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-6.png",
            "scheme": "https"
        }
    },
    "1998": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1998",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1998",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-13.png",
            "scheme": "https"
        }
    },
    "786": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #786",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "786",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-35.png",
            "scheme": "https"
        }
    },
    "922": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #922",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "922",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-15.png",
            "scheme": "https"
        }
    },
    "1515": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1515",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1515",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-40.png",
            "scheme": "https"
        }
    },
    "1533": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1533",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1533",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-29.png",
            "scheme": "https"
        }
    },
    "222": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #222",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "222",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-7.png",
            "scheme": "https"
        }
    },
    "683": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #683",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "683",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-24.png",
            "scheme": "https"
        }
    },
    "2207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2207",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2207",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-10.png",
            "scheme": "https"
        }
    },
    "2151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2151",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2151",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-17.png",
            "scheme": "https"
        }
    },
    "1243": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1243",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1243",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-5.png",
            "scheme": "https"
        }
    },
    "1371": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1371",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1371",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-47.png",
            "scheme": "https"
        }
    },
    "1011": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1011",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1011",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-7.png",
            "scheme": "https"
        }
    },
    "2051": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2051",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "2051",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-20.png",
            "scheme": "https"
        }
    },
    "1556": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1556",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1556",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-11.png",
            "scheme": "https"
        }
    },
    "2159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2159",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "2159",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-1.png",
            "scheme": "https"
        }
    },
    "581": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #581",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "581",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-31.png",
            "scheme": "https"
        }
    },
    "844": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #844",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "844",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-1.png",
            "scheme": "https"
        }
    },
    "89": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #89",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "89",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-41.png",
            "scheme": "https"
        }
    },
    "1661": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1661",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1661",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-2.png",
            "scheme": "https"
        }
    },
    "1102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1102",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1102",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-3.png",
            "scheme": "https"
        }
    },
    "1372": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1372",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1372",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-19.png",
            "scheme": "https"
        }
    },
    "413": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #413",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "413",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-9.png",
            "scheme": "https"
        }
    },
    "553": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #553",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "553",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-17.png",
            "scheme": "https"
        }
    },
    "1376": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1376",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1376",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-4.png",
            "scheme": "https"
        }
    },
    "982": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #982",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "982",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-27.png",
            "scheme": "https"
        }
    },
    "263": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #263",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "263",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-47.png",
            "scheme": "https"
        }
    },
    "1339": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1339",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1339",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-41.png",
            "scheme": "https"
        }
    },
    "1605": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1605",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1605",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-42.png",
            "scheme": "https"
        }
    },
    "1933": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1933",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1933",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-27.png",
            "scheme": "https"
        }
    },
    "1989": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1989",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1989",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-23.png",
            "scheme": "https"
        }
    },
    "56": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #56",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "56",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-38.png",
            "scheme": "https"
        }
    },
    "1298": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1298",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1298",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-7.png",
            "scheme": "https"
        }
    },
    "1880": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1880",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1880",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-39.png",
            "scheme": "https"
        }
    },
    "68": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #68",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "68",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-11.png",
            "scheme": "https"
        }
    },
    "470": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #470",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "470",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-40.png",
            "scheme": "https"
        }
    },
    "1685": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1685",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1685",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-23.png",
            "scheme": "https"
        }
    },
    "1965": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1965",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1965",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-7.png",
            "scheme": "https"
        }
    },
    "818": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #818",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "818",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-9.png",
            "scheme": "https"
        }
    },
    "536": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #536",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "536",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-36.png",
            "scheme": "https"
        }
    },
    "490": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #490",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "490",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-44.png",
            "scheme": "https"
        }
    },
    "1886": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1886",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1886",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-27.png",
            "scheme": "https"
        }
    },
    "793": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #793",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "793",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-29.png",
            "scheme": "https"
        }
    },
    "1567": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1567",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1567",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-43.png",
            "scheme": "https"
        }
    },
    "909": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #909",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "909",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-45.png",
            "scheme": "https"
        }
    },
    "618": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #618",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "618",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-41.png",
            "scheme": "https"
        }
    },
    "283": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #283",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "283",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-16.png",
            "scheme": "https"
        }
    },
    "1732": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1732",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1732",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-20.png",
            "scheme": "https"
        }
    },
    "487": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #487",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "487",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-34.png",
            "scheme": "https"
        }
    },
    "1953": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1953",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1953",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-5.png",
            "scheme": "https"
        }
    },
    "134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #134",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "134",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-11.png",
            "scheme": "https"
        }
    },
    "54": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #54",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "54",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-3.png",
            "scheme": "https"
        }
    },
    "1805": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1805",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1805",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-4.png",
            "scheme": "https"
        }
    },
    "1581": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1581",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1581",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-10.png",
            "scheme": "https"
        }
    },
    "1317": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1317",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1317",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-16.png",
            "scheme": "https"
        }
    },
    "862": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #862",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "862",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-20.png",
            "scheme": "https"
        }
    },
    "1921": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1921",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1921",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-16.png",
            "scheme": "https"
        }
    },
    "1713": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1713",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1713",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-14.png",
            "scheme": "https"
        }
    },
    "1207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1207",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1207",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-30.png",
            "scheme": "https"
        }
    },
    "527": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #527",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "527",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-18.png",
            "scheme": "https"
        }
    },
    "28": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #28",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "28",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-23.png",
            "scheme": "https"
        }
    },
    "469": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #469",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "469",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-16.png",
            "scheme": "https"
        }
    },
    "2184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2184",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "2184",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-11.png",
            "scheme": "https"
        }
    },
    "1635": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1635",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1635",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-34.png",
            "scheme": "https"
        }
    },
    "662": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #662",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "662",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-8.png",
            "scheme": "https"
        }
    },
    "137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #137",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "137",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-5.png",
            "scheme": "https"
        }
    },
    "2057": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2057",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2057",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-42.png",
            "scheme": "https"
        }
    },
    "112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #112",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "112",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-38.png",
            "scheme": "https"
        }
    },
    "2169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2169",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "2169",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-9.png",
            "scheme": "https"
        }
    },
    "1842": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1842",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1842",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-19.png",
            "scheme": "https"
        }
    },
    "1120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1120",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1120",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-32.png",
            "scheme": "https"
        }
    },
    "637": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #637",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "637",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-18.png",
            "scheme": "https"
        }
    },
    "1633": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1633",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1633",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-37.png",
            "scheme": "https"
        }
    },
    "1837": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1837",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1837",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-46.png",
            "scheme": "https"
        }
    },
    "645": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #645",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "645",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-32.png",
            "scheme": "https"
        }
    },
    "1699": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1699",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1699",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-34.png",
            "scheme": "https"
        }
    },
    "1509": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1509",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1509",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-27.png",
            "scheme": "https"
        }
    },
    "1788": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1788",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1788",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-44.png",
            "scheme": "https"
        }
    },
    "1056": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1056",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1056",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-27.png",
            "scheme": "https"
        }
    },
    "1331": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1331",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1331",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-24.png",
            "scheme": "https"
        }
    },
    "957": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #957",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "957",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-25.png",
            "scheme": "https"
        }
    },
    "479": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #479",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "479",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-34.png",
            "scheme": "https"
        }
    },
    "468": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #468",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "468",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-37.png",
            "scheme": "https"
        }
    },
    "1704": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1704",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1704",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-21.png",
            "scheme": "https"
        }
    },
    "1755": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1755",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1755",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-3.png",
            "scheme": "https"
        }
    },
    "562": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #562",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "562",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-3.png",
            "scheme": "https"
        }
    },
    "1859": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1859",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1859",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-30.png",
            "scheme": "https"
        }
    },
    "1465": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1465",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1465",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-39.png",
            "scheme": "https"
        }
    },
    "1026": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1026",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1026",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-9.png",
            "scheme": "https"
        }
    },
    "741": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #741",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "741",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-26.png",
            "scheme": "https"
        }
    },
    "1993": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1993",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1993",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-25.png",
            "scheme": "https"
        }
    },
    "132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #132",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "132",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-18.png",
            "scheme": "https"
        }
    },
    "427": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #427",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "427",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-15.png",
            "scheme": "https"
        }
    },
    "1716": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1716",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1716",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-32.png",
            "scheme": "https"
        }
    },
    "2098": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2098",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2098",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-22.png",
            "scheme": "https"
        }
    },
    "1821": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1821",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1821",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-43.png",
            "scheme": "https"
        }
    },
    "2119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2119",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "2119",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-15.png",
            "scheme": "https"
        }
    },
    "911": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #911",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "911",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-37.png",
            "scheme": "https"
        }
    },
    "2104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2104",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2104",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-47.png",
            "scheme": "https"
        }
    },
    "1167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1167",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1167",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-46.png",
            "scheme": "https"
        }
    },
    "1363": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1363",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1363",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-16.png",
            "scheme": "https"
        }
    },
    "664": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #664",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "664",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-45.png",
            "scheme": "https"
        }
    },
    "851": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #851",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "851",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-29.png",
            "scheme": "https"
        }
    },
    "228": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #228",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "228",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-36.png",
            "scheme": "https"
        }
    },
    "1521": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1521",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1521",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-42.png",
            "scheme": "https"
        }
    },
    "1547": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1547",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1547",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-16.png",
            "scheme": "https"
        }
    },
    "1612": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1612",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1612",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-19.png",
            "scheme": "https"
        }
    },
    "1790": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1790",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1790",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-35.png",
            "scheme": "https"
        }
    },
    "1336": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1336",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1336",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-40.png",
            "scheme": "https"
        }
    },
    "1365": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1365",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1365",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-26.png",
            "scheme": "https"
        }
    },
    "308": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #308",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "308",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-20.png",
            "scheme": "https"
        }
    },
    "1065": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1065",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1065",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-39.png",
            "scheme": "https"
        }
    },
    "188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #188",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "188",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-13.png",
            "scheme": "https"
        }
    },
    "348": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #348",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "348",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-21.png",
            "scheme": "https"
        }
    },
    "517": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #517",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "517",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-32.png",
            "scheme": "https"
        }
    },
    "2030": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2030",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2030",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-11.png",
            "scheme": "https"
        }
    },
    "1237": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1237",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1237",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-43.png",
            "scheme": "https"
        }
    },
    "609": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #609",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "609",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-6.png",
            "scheme": "https"
        }
    },
    "631": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #631",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "631",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-36.png",
            "scheme": "https"
        }
    },
    "1484": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1484",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1484",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-7.png",
            "scheme": "https"
        }
    },
    "1517": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1517",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1517",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-10.png",
            "scheme": "https"
        }
    },
    "291": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #291",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "291",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-29.png",
            "scheme": "https"
        }
    },
    "1822": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1822",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1822",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-6.png",
            "scheme": "https"
        }
    },
    "642": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #642",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "642",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-16.png",
            "scheme": "https"
        }
    },
    "232": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #232",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "232",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-33.png",
            "scheme": "https"
        }
    },
    "524": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #524",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "524",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-18.png",
            "scheme": "https"
        }
    },
    "1599": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1599",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1599",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-5.png",
            "scheme": "https"
        }
    },
    "273": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #273",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "273",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-2.png",
            "scheme": "https"
        }
    },
    "1240": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1240",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1240",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-12.png",
            "scheme": "https"
        }
    },
    "153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #153",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "153",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-12.png",
            "scheme": "https"
        }
    },
    "1094": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1094",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1094",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-16.png",
            "scheme": "https"
        }
    },
    "1222": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1222",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1222",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-5.png",
            "scheme": "https"
        }
    },
    "1265": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1265",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1265",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-25.png",
            "scheme": "https"
        }
    },
    "1959": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1959",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1959",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-8.png",
            "scheme": "https"
        }
    },
    "757": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #757",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "757",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-23.png",
            "scheme": "https"
        }
    },
    "1690": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1690",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1690",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-10.png",
            "scheme": "https"
        }
    },
    "97": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #97",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "97",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-46.png",
            "scheme": "https"
        }
    },
    "1223": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1223",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1223",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-4.png",
            "scheme": "https"
        }
    },
    "758": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #758",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "758",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-37.png",
            "scheme": "https"
        }
    },
    "38": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #38",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "38",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-27.png",
            "scheme": "https"
        }
    },
    "1961": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1961",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1961",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-17.png",
            "scheme": "https"
        }
    },
    "1711": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1711",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1711",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-24.png",
            "scheme": "https"
        }
    },
    "1796": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1796",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1796",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-2.png",
            "scheme": "https"
        }
    },
    "1006": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1006",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1006",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-44.png",
            "scheme": "https"
        }
    },
    "323": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #323",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "323",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-7.png",
            "scheme": "https"
        }
    },
    "611": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #611",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "611",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-1.png",
            "scheme": "https"
        }
    },
    "289": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #289",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "289",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-31.png",
            "scheme": "https"
        }
    },
    "1415": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1415",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1415",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-24.png",
            "scheme": "https"
        }
    },
    "1831": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1831",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1831",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-45.png",
            "scheme": "https"
        }
    },
    "1478": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1478",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1478",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-8.png",
            "scheme": "https"
        }
    },
    "4": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #4",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "4",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-28.png",
            "scheme": "https"
        }
    },
    "84": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #84",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "84",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-21.png",
            "scheme": "https"
        }
    },
    "1067": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1067",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1067",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-32.png",
            "scheme": "https"
        }
    },
    "736": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #736",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "736",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-18.png",
            "scheme": "https"
        }
    },
    "472": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #472",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "472",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-43.png",
            "scheme": "https"
        }
    },
    "428": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #428",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "428",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-16.png",
            "scheme": "https"
        }
    },
    "2109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2109",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "2109",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-41.png",
            "scheme": "https"
        }
    },
    "311": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #311",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "311",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-32.png",
            "scheme": "https"
        }
    },
    "1038": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1038",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1038",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-40.png",
            "scheme": "https"
        }
    },
    "1424": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1424",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1424",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-35.png",
            "scheme": "https"
        }
    },
    "1653": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1653",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1653",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-12.png",
            "scheme": "https"
        }
    },
    "387": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #387",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "387",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-45.png",
            "scheme": "https"
        }
    },
    "165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #165",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "165",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-44.png",
            "scheme": "https"
        }
    },
    "22": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #22",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "22",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-18.png",
            "scheme": "https"
        }
    },
    "1132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1132",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1132",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-2.png",
            "scheme": "https"
        }
    },
    "382": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #382",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "382",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-38.png",
            "scheme": "https"
        }
    },
    "1739": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1739",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1739",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-22.png",
            "scheme": "https"
        }
    },
    "1550": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1550",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1550",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-47.png",
            "scheme": "https"
        }
    },
    "400": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #400",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "400",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-31.png",
            "scheme": "https"
        }
    },
    "478": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #478",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "478",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-39.png",
            "scheme": "https"
        }
    },
    "1522": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1522",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1522",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-23.png",
            "scheme": "https"
        }
    },
    "1338": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1338",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1338",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-16.png",
            "scheme": "https"
        }
    },
    "1867": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1867",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1867",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-45.png",
            "scheme": "https"
        }
    },
    "1373": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1373",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1373",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-42.png",
            "scheme": "https"
        }
    },
    "2154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2154",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "2154",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-43.png",
            "scheme": "https"
        }
    },
    "878": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #878",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "878",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-3.png",
            "scheme": "https"
        }
    },
    "873": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #873",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "873",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-27.png",
            "scheme": "https"
        }
    },
    "896": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #896",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "896",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-13.png",
            "scheme": "https"
        }
    },
    "2088": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2088",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "2088",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-8.png",
            "scheme": "https"
        }
    },
    "33": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #33",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "33",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-14.png",
            "scheme": "https"
        }
    },
    "1083": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1083",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1083",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-36.png",
            "scheme": "https"
        }
    },
    "187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #187",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "187",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-19.png",
            "scheme": "https"
        }
    },
    "666": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #666",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "666",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-45.png",
            "scheme": "https"
        }
    },
    "2122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2122",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "2122",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-37.png",
            "scheme": "https"
        }
    },
    "34": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #34",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "34",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-19.png",
            "scheme": "https"
        }
    },
    "1375": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1375",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1375",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-27.png",
            "scheme": "https"
        }
    },
    "693": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #693",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "693",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-44.png",
            "scheme": "https"
        }
    },
    "879": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #879",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "879",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-30.png",
            "scheme": "https"
        }
    },
    "197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #197",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "197",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-37.png",
            "scheme": "https"
        }
    },
    "1044": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1044",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1044",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-33.png",
            "scheme": "https"
        }
    },
    "67": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #67",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "67",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-18.png",
            "scheme": "https"
        }
    },
    "292": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #292",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "292",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-31.png",
            "scheme": "https"
        }
    },
    "2208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2208",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "2208",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-33.png",
            "scheme": "https"
        }
    },
    "964": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #964",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "964",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-6.png",
            "scheme": "https"
        }
    },
    "1210": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1210",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1210",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-21.png",
            "scheme": "https"
        }
    },
    "1195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1195",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1195",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-5.png",
            "scheme": "https"
        }
    },
    "858": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #858",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "858",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-35.png",
            "scheme": "https"
        }
    },
    "37": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #37",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "37",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-7.png",
            "scheme": "https"
        }
    },
    "1571": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1571",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1571",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-16.png",
            "scheme": "https"
        }
    },
    "1931": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1931",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1931",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-13.png",
            "scheme": "https"
        }
    },
    "352": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #352",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "352",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-23.png",
            "scheme": "https"
        }
    },
    "525": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #525",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "525",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-13.png",
            "scheme": "https"
        }
    },
    "2186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2186",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "2186",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-29.png",
            "scheme": "https"
        }
    },
    "842": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #842",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "842",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-27.png",
            "scheme": "https"
        }
    },
    "1541": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1541",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1541",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-29.png",
            "scheme": "https"
        }
    },
    "893": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #893",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "893",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-11.png",
            "scheme": "https"
        }
    },
    "1456": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1456",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1456",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-39.png",
            "scheme": "https"
        }
    },
    "101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #101",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "101",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-16.png",
            "scheme": "https"
        }
    },
    "2160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2160",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "2160",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-15.png",
            "scheme": "https"
        }
    },
    "607": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #607",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "607",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-10.png",
            "scheme": "https"
        }
    },
    "449": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #449",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "449",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-38.png",
            "scheme": "https"
        }
    },
    "1941": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1941",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1941",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-34.png",
            "scheme": "https"
        }
    },
    "1787": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1787",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1787",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-16.png",
            "scheme": "https"
        }
    },
    "1861": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1861",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1861",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-2.png",
            "scheme": "https"
        }
    },
    "691": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #691",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "691",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-23.png",
            "scheme": "https"
        }
    },
    "318": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #318",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "318",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-16.png",
            "scheme": "https"
        }
    },
    "175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #175",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "175",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-24.png",
            "scheme": "https"
        }
    },
    "1860": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1860",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1860",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-41.png",
            "scheme": "https"
        }
    },
    "214": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #214",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "214",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-2.png",
            "scheme": "https"
        }
    },
    "359": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #359",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "359",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-39.png",
            "scheme": "https"
        }
    },
    "2144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2144",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "2144",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-40.png",
            "scheme": "https"
        }
    },
    "2120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2120",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "2120",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-8.png",
            "scheme": "https"
        }
    },
    "1414": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1414",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1414",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-8.png",
            "scheme": "https"
        }
    },
    "86": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #86",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "86",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-21.png",
            "scheme": "https"
        }
    },
    "332": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #332",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "332",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-18.png",
            "scheme": "https"
        }
    },
    "429": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #429",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "429",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-10.png",
            "scheme": "https"
        }
    },
    "1772": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1772",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1772",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-33.png",
            "scheme": "https"
        }
    },
    "1035": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1035",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1035",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-44.png",
            "scheme": "https"
        }
    },
    "671": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #671",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "671",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-1.png",
            "scheme": "https"
        }
    },
    "1226": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1226",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1226",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-21.png",
            "scheme": "https"
        }
    },
    "167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #167",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "167",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-36.png",
            "scheme": "https"
        }
    },
    "1203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1203",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1203",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-41.png",
            "scheme": "https"
        }
    },
    "526": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #526",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "526",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-1.png",
            "scheme": "https"
        }
    },
    "942": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #942",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "942",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-19.png",
            "scheme": "https"
        }
    },
    "393": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #393",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "393",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-29.png",
            "scheme": "https"
        }
    },
    "868": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #868",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "868",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-16.png",
            "scheme": "https"
        }
    },
    "169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #169",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "169",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-7.png",
            "scheme": "https"
        }
    },
    "2134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2134",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "2134",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-5.png",
            "scheme": "https"
        }
    },
    "1683": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1683",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1683",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-41.png",
            "scheme": "https"
        }
    },
    "2056": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2056",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "2056",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-32.png",
            "scheme": "https"
        }
    },
    "1097": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1097",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1097",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-26.png",
            "scheme": "https"
        }
    },
    "1028": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1028",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1028",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-32.png",
            "scheme": "https"
        }
    },
    "897": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #897",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "897",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-14.png",
            "scheme": "https"
        }
    },
    "1625": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1625",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1625",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-42.png",
            "scheme": "https"
        }
    },
    "935": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #935",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "935",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-41.png",
            "scheme": "https"
        }
    },
    "1852": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1852",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1852",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-3.png",
            "scheme": "https"
        }
    },
    "345": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #345",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "345",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-14.png",
            "scheme": "https"
        }
    },
    "414": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #414",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "414",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-14.png",
            "scheme": "https"
        }
    },
    "1357": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1357",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1357",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-6.png",
            "scheme": "https"
        }
    },
    "62": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #62",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "62",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-41.png",
            "scheme": "https"
        }
    },
    "2139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2139",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2139",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-32.png",
            "scheme": "https"
        }
    },
    "1928": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1928",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1928",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-21.png",
            "scheme": "https"
        }
    },
    "1278": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1278",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1278",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-10.png",
            "scheme": "https"
        }
    },
    "543": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #543",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "543",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-13.png",
            "scheme": "https"
        }
    },
    "319": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #319",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "319",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-7.png",
            "scheme": "https"
        }
    },
    "1467": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1467",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1467",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-12.png",
            "scheme": "https"
        }
    },
    "1606": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1606",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1606",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-20.png",
            "scheme": "https"
        }
    },
    "1401": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1401",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1401",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-39.png",
            "scheme": "https"
        }
    },
    "1912": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1912",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1912",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-30.png",
            "scheme": "https"
        }
    },
    "9": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #9",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "9",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-19.png",
            "scheme": "https"
        }
    },
    "1303": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1303",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1303",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-32.png",
            "scheme": "https"
        }
    },
    "1334": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1334",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1334",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-31.png",
            "scheme": "https"
        }
    },
    "805": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #805",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "805",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-8.png",
            "scheme": "https"
        }
    },
    "636": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #636",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "636",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-7.png",
            "scheme": "https"
        }
    },
    "2133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2133",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "2133",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-19.png",
            "scheme": "https"
        }
    },
    "1216": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1216",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1216",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-5.png",
            "scheme": "https"
        }
    },
    "710": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #710",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "710",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-13.png",
            "scheme": "https"
        }
    },
    "1616": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1616",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1616",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-8.png",
            "scheme": "https"
        }
    },
    "371": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #371",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "371",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-25.png",
            "scheme": "https"
        }
    },
    "672": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #672",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "672",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-30.png",
            "scheme": "https"
        }
    },
    "696": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #696",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "696",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-24.png",
            "scheme": "https"
        }
    },
    "1448": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1448",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1448",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-6.png",
            "scheme": "https"
        }
    },
    "1113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1113",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1113",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-15.png",
            "scheme": "https"
        }
    },
    "1555": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1555",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1555",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-15.png",
            "scheme": "https"
        }
    },
    "1990": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1990",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1990",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-47.png",
            "scheme": "https"
        }
    },
    "335": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #335",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "335",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-31.png",
            "scheme": "https"
        }
    },
    "1548": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1548",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1548",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-33.png",
            "scheme": "https"
        }
    },
    "864": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #864",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "864",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-1.png",
            "scheme": "https"
        }
    },
    "1899": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1899",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1899",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-18.png",
            "scheme": "https"
        }
    },
    "652": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #652",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "652",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-9.png",
            "scheme": "https"
        }
    },
    "1434": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1434",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1434",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-13.png",
            "scheme": "https"
        }
    },
    "201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #201",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "201",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-38.png",
            "scheme": "https"
        }
    },
    "392": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #392",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "392",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-18.png",
            "scheme": "https"
        }
    },
    "815": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #815",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "815",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-32.png",
            "scheme": "https"
        }
    },
    "2095": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2095",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "2095",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-25.png",
            "scheme": "https"
        }
    },
    "2178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2178",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "2178",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-42.png",
            "scheme": "https"
        }
    },
    "80": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #80",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "80",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-12.png",
            "scheme": "https"
        }
    },
    "794": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #794",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "794",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-23.png",
            "scheme": "https"
        }
    },
    "839": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #839",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "839",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-28.png",
            "scheme": "https"
        }
    },
    "1558": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1558",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1558",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-41.png",
            "scheme": "https"
        }
    },
    "739": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #739",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "739",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-11.png",
            "scheme": "https"
        }
    },
    "1427": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1427",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1427",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-44.png",
            "scheme": "https"
        }
    },
    "1530": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1530",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1530",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-15.png",
            "scheme": "https"
        }
    },
    "698": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #698",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "698",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-13.png",
            "scheme": "https"
        }
    },
    "149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #149",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "149",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-36.png",
            "scheme": "https"
        }
    },
    "1613": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1613",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1613",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-15.png",
            "scheme": "https"
        }
    },
    "76": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #76",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "76",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-39.png",
            "scheme": "https"
        }
    },
    "1228": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1228",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1228",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-27.png",
            "scheme": "https"
        }
    },
    "185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #185",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "185",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-9.png",
            "scheme": "https"
        }
    },
    "1411": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1411",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1411",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-33.png",
            "scheme": "https"
        }
    },
    "828": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #828",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "828",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-2.png",
            "scheme": "https"
        }
    },
    "364": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #364",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "364",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-38.png",
            "scheme": "https"
        }
    },
    "2069": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2069",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "2069",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-40.png",
            "scheme": "https"
        }
    },
    "852": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #852",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "852",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-25.png",
            "scheme": "https"
        }
    },
    "1251": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1251",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1251",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-15.png",
            "scheme": "https"
        }
    },
    "1275": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1275",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1275",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-15.png",
            "scheme": "https"
        }
    },
    "2163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2163",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "2163",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-17.png",
            "scheme": "https"
        }
    },
    "312": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #312",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "312",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-20.png",
            "scheme": "https"
        }
    },
    "1987": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1987",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1987",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-44.png",
            "scheme": "https"
        }
    },
    "2101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2101",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "2101",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-43.png",
            "scheme": "https"
        }
    },
    "819": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #819",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "819",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-24.png",
            "scheme": "https"
        }
    },
    "1288": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1288",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1288",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-42.png",
            "scheme": "https"
        }
    },
    "1991": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1991",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1991",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-4.png",
            "scheme": "https"
        }
    },
    "162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #162",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "162",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-15.png",
            "scheme": "https"
        }
    },
    "560": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #560",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "560",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-43.png",
            "scheme": "https"
        }
    },
    "1623": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1623",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1623",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-28.png",
            "scheme": "https"
        }
    },
    "443": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #443",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "443",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-14.png",
            "scheme": "https"
        }
    },
    "1593": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1593",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1593",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-31.png",
            "scheme": "https"
        }
    },
    "1428": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1428",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1428",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-17.png",
            "scheme": "https"
        }
    },
    "105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #105",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "105",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-11.png",
            "scheme": "https"
        }
    },
    "719": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #719",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "719",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-12.png",
            "scheme": "https"
        }
    },
    "1603": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1603",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1603",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-29.png",
            "scheme": "https"
        }
    },
    "2127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2127",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2127",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-21.png",
            "scheme": "https"
        }
    },
    "96": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #96",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "96",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-33.png",
            "scheme": "https"
        }
    },
    "998": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #998",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "998",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-20.png",
            "scheme": "https"
        }
    },
    "760": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #760",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "760",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-10.png",
            "scheme": "https"
        }
    },
    "1712": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1712",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1712",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-25.png",
            "scheme": "https"
        }
    },
    "375": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #375",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "375",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-35.png",
            "scheme": "https"
        }
    },
    "1932": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1932",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1932",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-15.png",
            "scheme": "https"
        }
    },
    "42": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #42",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "42",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-28.png",
            "scheme": "https"
        }
    },
    "877": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #877",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "877",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-13.png",
            "scheme": "https"
        }
    },
    "1283": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1283",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1283",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-3.png",
            "scheme": "https"
        }
    },
    "1452": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1452",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1452",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-7.png",
            "scheme": "https"
        }
    },
    "341": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #341",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "341",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-6.png",
            "scheme": "https"
        }
    },
    "889": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #889",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "889",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-13.png",
            "scheme": "https"
        }
    },
    "1737": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1737",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1737",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-18.png",
            "scheme": "https"
        }
    },
    "1646": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1646",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1646",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-45.png",
            "scheme": "https"
        }
    },
    "2065": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2065",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2065",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-9.png",
            "scheme": "https"
        }
    },
    "1973": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1973",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1973",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-10.png",
            "scheme": "https"
        }
    },
    "2165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2165",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2165",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-15.png",
            "scheme": "https"
        }
    },
    "1890": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1890",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1890",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-23.png",
            "scheme": "https"
        }
    },
    "147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #147",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "147",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-41.png",
            "scheme": "https"
        }
    },
    "2166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2166",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "2166",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-22.png",
            "scheme": "https"
        }
    },
    "1609": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1609",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1609",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-22.png",
            "scheme": "https"
        }
    },
    "177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #177",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "177",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-30.png",
            "scheme": "https"
        }
    },
    "2125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2125",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2125",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-2.png",
            "scheme": "https"
        }
    },
    "300": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #300",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "300",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-27.png",
            "scheme": "https"
        }
    },
    "224": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #224",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "224",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-17.png",
            "scheme": "https"
        }
    },
    "555": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #555",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "555",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-24.png",
            "scheme": "https"
        }
    },
    "546": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #546",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "546",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-44.png",
            "scheme": "https"
        }
    },
    "1949": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1949",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1949",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-39.png",
            "scheme": "https"
        }
    },
    "1482": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1482",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1482",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-20.png",
            "scheme": "https"
        }
    },
    "1142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1142",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1142",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-10.png",
            "scheme": "https"
        }
    },
    "59": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #59",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "59",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-34.png",
            "scheme": "https"
        }
    },
    "1350": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1350",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1350",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-29.png",
            "scheme": "https"
        }
    },
    "538": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #538",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "538",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-40.png",
            "scheme": "https"
        }
    },
    "2047": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2047",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2047",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-24.png",
            "scheme": "https"
        }
    },
    "1883": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1883",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1883",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-24.png",
            "scheme": "https"
        }
    },
    "163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #163",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "163",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-18.png",
            "scheme": "https"
        }
    },
    "721": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #721",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "721",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-18.png",
            "scheme": "https"
        }
    },
    "1544": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1544",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1544",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-37.png",
            "scheme": "https"
        }
    },
    "270": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #270",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "270",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-26.png",
            "scheme": "https"
        }
    },
    "576": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #576",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "576",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-21.png",
            "scheme": "https"
        }
    },
    "1730": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1730",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1730",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-29.png",
            "scheme": "https"
        }
    },
    "1082": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1082",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1082",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-8.png",
            "scheme": "https"
        }
    },
    "1154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1154",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1154",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-21.png",
            "scheme": "https"
        }
    },
    "2039": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2039",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "2039",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-26.png",
            "scheme": "https"
        }
    },
    "941": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #941",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "941",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-8.png",
            "scheme": "https"
        }
    },
    "1527": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1527",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1527",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-2.png",
            "scheme": "https"
        }
    },
    "1241": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1241",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1241",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-18.png",
            "scheme": "https"
        }
    },
    "1197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1197",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1197",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-41.png",
            "scheme": "https"
        }
    },
    "894": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #894",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "894",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-3.png",
            "scheme": "https"
        }
    },
    "1219": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1219",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1219",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-42.png",
            "scheme": "https"
        }
    },
    "1407": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1407",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1407",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-12.png",
            "scheme": "https"
        }
    },
    "1040": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1040",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1040",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-18.png",
            "scheme": "https"
        }
    },
    "1395": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1395",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1395",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-22.png",
            "scheme": "https"
        }
    },
    "1783": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1783",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1783",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-32.png",
            "scheme": "https"
        }
    },
    "1643": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1643",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1643",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-33.png",
            "scheme": "https"
        }
    },
    "800": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #800",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "800",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-22.png",
            "scheme": "https"
        }
    },
    "1332": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1332",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1332",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-7.png",
            "scheme": "https"
        }
    },
    "1184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1184",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1184",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-20.png",
            "scheme": "https"
        }
    },
    "1952": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1952",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1952",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-23.png",
            "scheme": "https"
        }
    },
    "1419": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1419",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1419",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-25.png",
            "scheme": "https"
        }
    },
    "82": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #82",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "82",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-5.png",
            "scheme": "https"
        }
    },
    "202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #202",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "202",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-39.png",
            "scheme": "https"
        }
    },
    "1524": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1524",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1524",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-32.png",
            "scheme": "https"
        }
    },
    "3": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #3",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "3",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-32.png",
            "scheme": "https"
        }
    },
    "1810": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1810",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1810",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-20.png",
            "scheme": "https"
        }
    },
    "2102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2102",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "2102",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-43.png",
            "scheme": "https"
        }
    },
    "2111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2111",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "2111",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-14.png",
            "scheme": "https"
        }
    },
    "70": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #70",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "70",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-4.png",
            "scheme": "https"
        }
    },
    "1364": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1364",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1364",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-5.png",
            "scheme": "https"
        }
    },
    "1854": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1854",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1854",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-23.png",
            "scheme": "https"
        }
    },
    "217": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #217",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "217",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-27.png",
            "scheme": "https"
        }
    },
    "2177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2177",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "2177",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-43.png",
            "scheme": "https"
        }
    },
    "465": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #465",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "465",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-13.png",
            "scheme": "https"
        }
    },
    "1784": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1784",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1784",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-29.png",
            "scheme": "https"
        }
    },
    "370": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #370",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "370",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-12.png",
            "scheme": "https"
        }
    },
    "1972": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1972",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1972",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-30.png",
            "scheme": "https"
        }
    },
    "715": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #715",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "715",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-28.png",
            "scheme": "https"
        }
    },
    "98": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #98",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "98",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-36.png",
            "scheme": "https"
        }
    },
    "1055": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1055",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1055",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-46.png",
            "scheme": "https"
        }
    },
    "1052": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1052",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1052",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-22.png",
            "scheme": "https"
        }
    },
    "1835": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1835",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1835",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-33.png",
            "scheme": "https"
        }
    },
    "687": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #687",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "687",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-19.png",
            "scheme": "https"
        }
    },
    "588": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #588",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "588",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-30.png",
            "scheme": "https"
        }
    },
    "122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #122",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "122",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-25.png",
            "scheme": "https"
        }
    },
    "1019": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1019",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1019",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-26.png",
            "scheme": "https"
        }
    },
    "1694": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1694",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1694",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-43.png",
            "scheme": "https"
        }
    },
    "617": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #617",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "617",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-22.png",
            "scheme": "https"
        }
    },
    "418": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #418",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "418",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-47.png",
            "scheme": "https"
        }
    },
    "2007": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2007",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "2007",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-9.png",
            "scheme": "https"
        }
    },
    "108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #108",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "108",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-39.png",
            "scheme": "https"
        }
    },
    "1590": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1590",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1590",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-19.png",
            "scheme": "https"
        }
    },
    "619": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #619",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "619",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-20.png",
            "scheme": "https"
        }
    },
    "1773": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1773",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1773",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-26.png",
            "scheme": "https"
        }
    },
    "658": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #658",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "658",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-1.png",
            "scheme": "https"
        }
    },
    "258": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #258",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "258",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-8.png",
            "scheme": "https"
        }
    },
    "1938": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1938",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1938",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-2.png",
            "scheme": "https"
        }
    },
    "1792": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1792",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1792",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-11.png",
            "scheme": "https"
        }
    },
    "681": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #681",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "681",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-47.png",
            "scheme": "https"
        }
    },
    "1847": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1847",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1847",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-36.png",
            "scheme": "https"
        }
    },
    "2137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2137",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2137",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-7.png",
            "scheme": "https"
        }
    },
    "777": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #777",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "777",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-15.png",
            "scheme": "https"
        }
    },
    "1378": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1378",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1378",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-23.png",
            "scheme": "https"
        }
    },
    "65": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #65",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "65",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-47.png",
            "scheme": "https"
        }
    },
    "2209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2209",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "2209",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-30.png",
            "scheme": "https"
        }
    },
    "829": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #829",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "829",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-46.png",
            "scheme": "https"
        }
    },
    "837": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #837",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "837",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-27.png",
            "scheme": "https"
        }
    },
    "2164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2164",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2164",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-18.png",
            "scheme": "https"
        }
    },
    "81": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #81",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "81",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-18.png",
            "scheme": "https"
        }
    },
    "334": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #334",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "334",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-42.png",
            "scheme": "https"
        }
    },
    "1000": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1000",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1000",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-7.png",
            "scheme": "https"
        }
    },
    "322": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #322",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "322",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-43.png",
            "scheme": "https"
        }
    },
    "209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #209",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "209",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-36.png",
            "scheme": "https"
        }
    },
    "455": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #455",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "455",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-1.png",
            "scheme": "https"
        }
    },
    "754": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #754",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "754",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-21.png",
            "scheme": "https"
        }
    },
    "32": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #32",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "32",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-2.png",
            "scheme": "https"
        }
    },
    "1619": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1619",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1619",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-6.png",
            "scheme": "https"
        }
    },
    "63": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #63",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "63",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-36.png",
            "scheme": "https"
        }
    },
    "1136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1136",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1136",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-41.png",
            "scheme": "https"
        }
    },
    "2103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2103",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "2103",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-24.png",
            "scheme": "https"
        }
    },
    "1771": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1771",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1771",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-14.png",
            "scheme": "https"
        }
    },
    "1597": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1597",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1597",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-39.png",
            "scheme": "https"
        }
    },
    "665": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #665",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "665",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-47.png",
            "scheme": "https"
        }
    },
    "602": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #602",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "602",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-18.png",
            "scheme": "https"
        }
    },
    "1565": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1565",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1565",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-11.png",
            "scheme": "https"
        }
    },
    "1738": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1738",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1738",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-1.png",
            "scheme": "https"
        }
    },
    "1721": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1721",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1721",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-5.png",
            "scheme": "https"
        }
    },
    "486": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #486",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "486",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-31.png",
            "scheme": "https"
        }
    },
    "1075": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1075",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1075",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-7.png",
            "scheme": "https"
        }
    },
    "2036": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2036",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "2036",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-25.png",
            "scheme": "https"
        }
    },
    "963": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #963",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "963",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-27.png",
            "scheme": "https"
        }
    },
    "1793": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1793",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1793",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-41.png",
            "scheme": "https"
        }
    },
    "773": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #773",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "773",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-41.png",
            "scheme": "https"
        }
    },
    "548": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #548",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "548",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-39.png",
            "scheme": "https"
        }
    },
    "1435": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1435",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1435",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-44.png",
            "scheme": "https"
        }
    },
    "1642": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1642",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1642",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-23.png",
            "scheme": "https"
        }
    },
    "972": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #972",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "972",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-27.png",
            "scheme": "https"
        }
    },
    "1299": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1299",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1299",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-44.png",
            "scheme": "https"
        }
    },
    "570": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #570",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "570",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-25.png",
            "scheme": "https"
        }
    },
    "1836": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1836",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1836",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-9.png",
            "scheme": "https"
        }
    },
    "419": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #419",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "419",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-36.png",
            "scheme": "https"
        }
    },
    "1107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1107",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1107",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-40.png",
            "scheme": "https"
        }
    },
    "1150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1150",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1150",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-29.png",
            "scheme": "https"
        }
    },
    "580": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #580",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "580",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-12.png",
            "scheme": "https"
        }
    },
    "1479": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1479",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1479",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-28.png",
            "scheme": "https"
        }
    },
    "1976": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1976",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1976",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-14.png",
            "scheme": "https"
        }
    },
    "139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #139",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "139",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-2.png",
            "scheme": "https"
        }
    },
    "1200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1200",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1200",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-12.png",
            "scheme": "https"
        }
    },
    "976": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #976",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "976",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-1.png",
            "scheme": "https"
        }
    },
    "253": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #253",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "253",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-9.png",
            "scheme": "https"
        }
    },
    "1295": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1295",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1295",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-13.png",
            "scheme": "https"
        }
    },
    "2130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2130",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "2130",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-10.png",
            "scheme": "https"
        }
    },
    "1719": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1719",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1719",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-42.png",
            "scheme": "https"
        }
    },
    "120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #120",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "120",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-47.png",
            "scheme": "https"
        }
    },
    "1763": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1763",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1763",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-23.png",
            "scheme": "https"
        }
    },
    "1005": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1005",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1005",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-23.png",
            "scheme": "https"
        }
    },
    "1192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1192",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1192",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-20.png",
            "scheme": "https"
        }
    },
    "2058": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2058",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "2058",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-28.png",
            "scheme": "https"
        }
    },
    "1125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1125",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1125",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-25.png",
            "scheme": "https"
        }
    },
    "1648": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1648",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1648",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-40.png",
            "scheme": "https"
        }
    },
    "118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #118",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "118",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-17.png",
            "scheme": "https"
        }
    },
    "949": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #949",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "949",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-37.png",
            "scheme": "https"
        }
    },
    "1276": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1276",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1276",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-29.png",
            "scheme": "https"
        }
    },
    "1512": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1512",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1512",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-31.png",
            "scheme": "https"
        }
    },
    "1246": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1246",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1246",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-37.png",
            "scheme": "https"
        }
    },
    "213": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #213",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "213",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-18.png",
            "scheme": "https"
        }
    },
    "514": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #514",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "514",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-26.png",
            "scheme": "https"
        }
    },
    "1468": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1468",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1468",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-34.png",
            "scheme": "https"
        }
    },
    "1225": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1225",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1225",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-40.png",
            "scheme": "https"
        }
    },
    "2146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2146",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "2146",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-26.png",
            "scheme": "https"
        }
    },
    "325": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #325",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "325",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-25.png",
            "scheme": "https"
        }
    },
    "544": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #544",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "544",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-20.png",
            "scheme": "https"
        }
    },
    "781": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #781",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "781",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-45.png",
            "scheme": "https"
        }
    },
    "1140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1140",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1140",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-9.png",
            "scheme": "https"
        }
    },
    "1221": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1221",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1221",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-17.png",
            "scheme": "https"
        }
    },
    "727": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #727",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "727",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-1.png",
            "scheme": "https"
        }
    },
    "702": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #702",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "702",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-17.png",
            "scheme": "https"
        }
    },
    "1437": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1437",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1437",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-9.png",
            "scheme": "https"
        }
    },
    "554": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #554",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "554",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-8.png",
            "scheme": "https"
        }
    },
    "1098": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1098",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1098",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-18.png",
            "scheme": "https"
        }
    },
    "1807": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1807",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1807",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-6.png",
            "scheme": "https"
        }
    },
    "895": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #895",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "895",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-5.png",
            "scheme": "https"
        }
    },
    "1667": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1667",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1667",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-25.png",
            "scheme": "https"
        }
    },
    "1205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1205",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1205",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-34.png",
            "scheme": "https"
        }
    },
    "353": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #353",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "353",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-4.png",
            "scheme": "https"
        }
    },
    "510": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #510",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "510",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-15.png",
            "scheme": "https"
        }
    },
    "920": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #920",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "920",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-33.png",
            "scheme": "https"
        }
    },
    "529": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #529",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "529",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-2.png",
            "scheme": "https"
        }
    },
    "1915": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1915",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1915",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-40.png",
            "scheme": "https"
        }
    },
    "5": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #5",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "5",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-6.png",
            "scheme": "https"
        }
    },
    "215": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #215",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "215",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-23.png",
            "scheme": "https"
        }
    },
    "831": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #831",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "831",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-6.png",
            "scheme": "https"
        }
    },
    "244": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #244",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "244",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-46.png",
            "scheme": "https"
        }
    },
    "106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #106",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "106",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-20.png",
            "scheme": "https"
        }
    },
    "2117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2117",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2117",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-46.png",
            "scheme": "https"
        }
    },
    "984": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #984",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "984",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-32.png",
            "scheme": "https"
        }
    },
    "2086": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2086",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2086",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-41.png",
            "scheme": "https"
        }
    },
    "8": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #8",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "8",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-4.png",
            "scheme": "https"
        }
    },
    "324": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #324",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "324",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-26.png",
            "scheme": "https"
        }
    },
    "2068": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2068",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "2068",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-19.png",
            "scheme": "https"
        }
    },
    "1393": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1393",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1393",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-26.png",
            "scheme": "https"
        }
    },
    "1012": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1012",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1012",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-35.png",
            "scheme": "https"
        }
    },
    "640": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #640",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "640",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-13.png",
            "scheme": "https"
        }
    },
    "934": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #934",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "934",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-11.png",
            "scheme": "https"
        }
    },
    "551": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #551",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "551",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-39.png",
            "scheme": "https"
        }
    },
    "200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #200",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "200",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-20.png",
            "scheme": "https"
        }
    },
    "186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #186",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "186",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-39.png",
            "scheme": "https"
        }
    },
    "753": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #753",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "753",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-30.png",
            "scheme": "https"
        }
    },
    "226": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #226",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "226",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-20.png",
            "scheme": "https"
        }
    },
    "1436": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1436",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1436",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-46.png",
            "scheme": "https"
        }
    },
    "1744": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1744",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1744",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-20.png",
            "scheme": "https"
        }
    },
    "2054": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2054",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "2054",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-27.png",
            "scheme": "https"
        }
    },
    "1617": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1617",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1617",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-34.png",
            "scheme": "https"
        }
    },
    "959": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #959",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "959",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-35.png",
            "scheme": "https"
        }
    },
    "1564": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1564",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1564",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-3.png",
            "scheme": "https"
        }
    },
    "1700": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1700",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1700",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-16.png",
            "scheme": "https"
        }
    },
    "885": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #885",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "885",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-4.png",
            "scheme": "https"
        }
    },
    "1780": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1780",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1780",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-2.png",
            "scheme": "https"
        }
    },
    "1926": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1926",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1926",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-38.png",
            "scheme": "https"
        }
    },
    "1099": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1099",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1099",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-2.png",
            "scheme": "https"
        }
    },
    "1666": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1666",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1666",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-15.png",
            "scheme": "https"
        }
    },
    "2204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2204",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "2204",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-39.png",
            "scheme": "https"
        }
    },
    "1262": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1262",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1262",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-39.png",
            "scheme": "https"
        }
    },
    "166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #166",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "166",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-22.png",
            "scheme": "https"
        }
    },
    "1294": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1294",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1294",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-38.png",
            "scheme": "https"
        }
    },
    "1820": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1820",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1820",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-7.png",
            "scheme": "https"
        }
    },
    "1254": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1254",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1254",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-34.png",
            "scheme": "https"
        }
    },
    "1322": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1322",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1322",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-22.png",
            "scheme": "https"
        }
    },
    "990": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #990",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "990",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-46.png",
            "scheme": "https"
        }
    },
    "1767": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1767",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1767",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-17.png",
            "scheme": "https"
        }
    },
    "774": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #774",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "774",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-22.png",
            "scheme": "https"
        }
    },
    "763": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #763",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "763",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-13.png",
            "scheme": "https"
        }
    },
    "1057": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1057",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1057",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-31.png",
            "scheme": "https"
        }
    },
    "1231": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1231",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1231",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-39.png",
            "scheme": "https"
        }
    },
    "1449": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1449",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1449",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-3.png",
            "scheme": "https"
        }
    },
    "220": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #220",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "220",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-27.png",
            "scheme": "https"
        }
    },
    "1560": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1560",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1560",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-21.png",
            "scheme": "https"
        }
    },
    "806": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #806",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "806",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-45.png",
            "scheme": "https"
        }
    },
    "1326": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1326",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1326",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-9.png",
            "scheme": "https"
        }
    },
    "398": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #398",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "398",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-29.png",
            "scheme": "https"
        }
    },
    "40": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #40",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "40",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-27.png",
            "scheme": "https"
        }
    },
    "559": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #559",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "559",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-35.png",
            "scheme": "https"
        }
    },
    "1460": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1460",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1460",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-20.png",
            "scheme": "https"
        }
    },
    "803": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #803",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "803",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-26.png",
            "scheme": "https"
        }
    },
    "100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #100",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "100",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-10.png",
            "scheme": "https"
        }
    },
    "275": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #275",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "275",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-36.png",
            "scheme": "https"
        }
    },
    "579": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #579",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "579",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-14.png",
            "scheme": "https"
        }
    },
    "699": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #699",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "699",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-38.png",
            "scheme": "https"
        }
    },
    "1352": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1352",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1352",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-41.png",
            "scheme": "https"
        }
    },
    "1423": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1423",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1423",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-18.png",
            "scheme": "https"
        }
    },
    "1268": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1268",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1268",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-27.png",
            "scheme": "https"
        }
    },
    "726": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #726",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "726",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-35.png",
            "scheme": "https"
        }
    },
    "1832": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1832",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1832",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-36.png",
            "scheme": "https"
        }
    },
    "1068": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1068",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1068",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-35.png",
            "scheme": "https"
        }
    },
    "1710": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1710",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1710",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-36.png",
            "scheme": "https"
        }
    },
    "1902": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1902",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1902",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-36.png",
            "scheme": "https"
        }
    },
    "961": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #961",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "961",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-9.png",
            "scheme": "https"
        }
    },
    "1157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1157",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1157",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-4.png",
            "scheme": "https"
        }
    },
    "1489": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1489",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1489",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-26.png",
            "scheme": "https"
        }
    },
    "816": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #816",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "816",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-36.png",
            "scheme": "https"
        }
    },
    "1208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1208",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1208",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-4.png",
            "scheme": "https"
        }
    },
    "272": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #272",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "272",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-4.png",
            "scheme": "https"
        }
    },
    "321": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #321",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "321",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-20.png",
            "scheme": "https"
        }
    },
    "1487": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1487",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1487",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-20.png",
            "scheme": "https"
        }
    },
    "1198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1198",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1198",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-23.png",
            "scheme": "https"
        }
    },
    "41": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #41",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "41",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-43.png",
            "scheme": "https"
        }
    },
    "714": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #714",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "714",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-43.png",
            "scheme": "https"
        }
    },
    "1175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1175",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1175",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-7.png",
            "scheme": "https"
        }
    },
    "583": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #583",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "583",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-13.png",
            "scheme": "https"
        }
    },
    "236": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #236",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "236",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-18.png",
            "scheme": "https"
        }
    },
    "1290": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1290",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1290",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-35.png",
            "scheme": "https"
        }
    },
    "871": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #871",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "871",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-4.png",
            "scheme": "https"
        }
    },
    "320": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #320",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "320",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-37.png",
            "scheme": "https"
        }
    },
    "1387": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1387",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1387",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-38.png",
            "scheme": "https"
        }
    },
    "1908": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1908",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1908",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-25.png",
            "scheme": "https"
        }
    },
    "1105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1105",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1105",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-25.png",
            "scheme": "https"
        }
    },
    "203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #203",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "203",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-34.png",
            "scheme": "https"
        }
    },
    "1353": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1353",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1353",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-24.png",
            "scheme": "https"
        }
    },
    "1383": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1383",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1383",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-47.png",
            "scheme": "https"
        }
    },
    "159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #159",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "159",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-3.png",
            "scheme": "https"
        }
    },
    "722": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #722",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "722",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-45.png",
            "scheme": "https"
        }
    },
    "1043": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1043",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1043",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-18.png",
            "scheme": "https"
        }
    },
    "243": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #243",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "243",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-26.png",
            "scheme": "https"
        }
    },
    "804": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #804",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "804",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-29.png",
            "scheme": "https"
        }
    },
    "66": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #66",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "66",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-1.png",
            "scheme": "https"
        }
    },
    "264": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #264",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "264",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-1.png",
            "scheme": "https"
        }
    },
    "1095": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1095",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1095",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-36.png",
            "scheme": "https"
        }
    },
    "1174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1174",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1174",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-40.png",
            "scheme": "https"
        }
    },
    "1907": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1907",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1907",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-10.png",
            "scheme": "https"
        }
    },
    "234": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #234",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "234",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-23.png",
            "scheme": "https"
        }
    },
    "1829": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1829",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1829",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-31.png",
            "scheme": "https"
        }
    },
    "1971": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1971",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1971",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-16.png",
            "scheme": "https"
        }
    },
    "1297": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1297",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1297",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-19.png",
            "scheme": "https"
        }
    },
    "2148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2148",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "2148",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-13.png",
            "scheme": "https"
        }
    },
    "2013": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2013",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2013",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-29.png",
            "scheme": "https"
        }
    },
    "567": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #567",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "567",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-5.png",
            "scheme": "https"
        }
    },
    "509": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #509",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "509",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-26.png",
            "scheme": "https"
        }
    },
    "432": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #432",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "432",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-45.png",
            "scheme": "https"
        }
    },
    "1806": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1806",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1806",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-5.png",
            "scheme": "https"
        }
    },
    "1013": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1013",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1013",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-23.png",
            "scheme": "https"
        }
    },
    "980": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #980",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "980",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-24.png",
            "scheme": "https"
        }
    },
    "2050": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2050",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2050",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-39.png",
            "scheme": "https"
        }
    },
    "625": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #625",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "625",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-6.png",
            "scheme": "https"
        }
    },
    "1851": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1851",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1851",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-11.png",
            "scheme": "https"
        }
    },
    "591": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #591",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "591",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-22.png",
            "scheme": "https"
        }
    },
    "2150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2150",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "2150",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-20.png",
            "scheme": "https"
        }
    },
    "1379": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1379",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1379",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-42.png",
            "scheme": "https"
        }
    },
    "1621": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1621",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1621",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-32.png",
            "scheme": "https"
        }
    },
    "1673": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1673",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1673",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-1.png",
            "scheme": "https"
        }
    },
    "1062": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1062",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1062",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-14.png",
            "scheme": "https"
        }
    },
    "1579": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1579",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1579",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-40.png",
            "scheme": "https"
        }
    },
    "1561": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1561",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1561",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-11.png",
            "scheme": "https"
        }
    },
    "950": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #950",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "950",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-22.png",
            "scheme": "https"
        }
    },
    "252": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #252",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "252",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-30.png",
            "scheme": "https"
        }
    },
    "504": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #504",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "504",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-24.png",
            "scheme": "https"
        }
    },
    "1964": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1964",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1964",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-3.png",
            "scheme": "https"
        }
    },
    "1171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1171",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1171",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-29.png",
            "scheme": "https"
        }
    },
    "294": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #294",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "294",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-24.png",
            "scheme": "https"
        }
    },
    "1542": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1542",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1542",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-4.png",
            "scheme": "https"
        }
    },
    "1061": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1061",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1061",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-26.png",
            "scheme": "https"
        }
    },
    "505": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #505",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "505",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-11.png",
            "scheme": "https"
        }
    },
    "1463": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1463",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1463",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-3.png",
            "scheme": "https"
        }
    },
    "1963": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1963",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1963",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-37.png",
            "scheme": "https"
        }
    },
    "1627": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1627",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1627",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-14.png",
            "scheme": "https"
        }
    },
    "927": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #927",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "927",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-23.png",
            "scheme": "https"
        }
    },
    "2027": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2027",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2027",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-35.png",
            "scheme": "https"
        }
    },
    "13": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #13",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "13",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-45.png",
            "scheme": "https"
        }
    },
    "73": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #73",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "73",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-8.png",
            "scheme": "https"
        }
    },
    "1374": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1374",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1374",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-19.png",
            "scheme": "https"
        }
    },
    "1723": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1723",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1723",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-16.png",
            "scheme": "https"
        }
    },
    "471": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #471",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "471",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-36.png",
            "scheme": "https"
        }
    },
    "1284": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1284",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1284",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-22.png",
            "scheme": "https"
        }
    },
    "2043": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2043",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "2043",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-47.png",
            "scheme": "https"
        }
    },
    "1330": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1330",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1330",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-19.png",
            "scheme": "https"
        }
    },
    "1501": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1501",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1501",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-28.png",
            "scheme": "https"
        }
    },
    "921": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #921",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "921",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-44.png",
            "scheme": "https"
        }
    },
    "2011": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2011",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "2011",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-44.png",
            "scheme": "https"
        }
    },
    "130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #130",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "130",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-32.png",
            "scheme": "https"
        }
    },
    "1104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1104",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1104",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-33.png",
            "scheme": "https"
        }
    },
    "1566": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1566",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1566",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-17.png",
            "scheme": "https"
        }
    },
    "1813": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1813",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1813",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-34.png",
            "scheme": "https"
        }
    },
    "1927": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1927",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1927",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-35.png",
            "scheme": "https"
        }
    },
    "725": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #725",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "725",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-8.png",
            "scheme": "https"
        }
    },
    "1798": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1798",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1798",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-18.png",
            "scheme": "https"
        }
    },
    "1085": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1085",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1085",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-36.png",
            "scheme": "https"
        }
    },
    "1895": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1895",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1895",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-42.png",
            "scheme": "https"
        }
    },
    "1985": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1985",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1985",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-47.png",
            "scheme": "https"
        }
    },
    "241": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #241",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "241",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-45.png",
            "scheme": "https"
        }
    },
    "845": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #845",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "845",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-12.png",
            "scheme": "https"
        }
    },
    "914": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #914",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "914",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-37.png",
            "scheme": "https"
        }
    },
    "2140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2140",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "2140",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-13.png",
            "scheme": "https"
        }
    },
    "780": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #780",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "780",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-20.png",
            "scheme": "https"
        }
    },
    "905": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #905",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "905",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-30.png",
            "scheme": "https"
        }
    },
    "907": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #907",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "907",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-17.png",
            "scheme": "https"
        }
    },
    "720": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #720",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "720",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-3.png",
            "scheme": "https"
        }
    },
    "1291": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1291",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1291",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-9.png",
            "scheme": "https"
        }
    },
    "1218": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1218",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1218",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-11.png",
            "scheme": "https"
        }
    },
    "145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #145",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "145",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-28.png",
            "scheme": "https"
        }
    },
    "1391": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1391",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1391",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-8.png",
            "scheme": "https"
        }
    },
    "495": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #495",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "495",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-23.png",
            "scheme": "https"
        }
    },
    "439": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #439",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "439",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-44.png",
            "scheme": "https"
        }
    },
    "521": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #521",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "521",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-42.png",
            "scheme": "https"
        }
    },
    "1505": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1505",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1505",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-28.png",
            "scheme": "https"
        }
    },
    "389": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #389",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "389",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-17.png",
            "scheme": "https"
        }
    },
    "762": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #762",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "762",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-2.png",
            "scheme": "https"
        }
    },
    "506": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #506",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "506",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-47.png",
            "scheme": "https"
        }
    },
    "415": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #415",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "415",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-9.png",
            "scheme": "https"
        }
    },
    "373": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #373",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "373",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-5.png",
            "scheme": "https"
        }
    },
    "307": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #307",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "307",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-6.png",
            "scheme": "https"
        }
    },
    "659": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #659",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "659",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-23.png",
            "scheme": "https"
        }
    },
    "1164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1164",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1164",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-19.png",
            "scheme": "https"
        }
    },
    "460": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #460",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "460",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-40.png",
            "scheme": "https"
        }
    },
    "481": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #481",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "481",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-35.png",
            "scheme": "https"
        }
    },
    "2094": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2094",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "2094",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-17.png",
            "scheme": "https"
        }
    },
    "171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #171",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "171",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-11.png",
            "scheme": "https"
        }
    },
    "1129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1129",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1129",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-30.png",
            "scheme": "https"
        }
    },
    "27": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #27",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "27",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-6.png",
            "scheme": "https"
        }
    },
    "1552": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1552",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1552",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-33.png",
            "scheme": "https"
        }
    },
    "1323": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1323",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1323",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-40.png",
            "scheme": "https"
        }
    },
    "2108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2108",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "2108",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-38.png",
            "scheme": "https"
        }
    },
    "639": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #639",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "639",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-38.png",
            "scheme": "https"
        }
    },
    "647": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #647",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "647",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-32.png",
            "scheme": "https"
        }
    },
    "1215": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1215",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1215",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-26.png",
            "scheme": "https"
        }
    },
    "1819": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1819",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1819",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-16.png",
            "scheme": "https"
        }
    },
    "1982": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1982",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1982",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-45.png",
            "scheme": "https"
        }
    },
    "452": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #452",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "452",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-10.png",
            "scheme": "https"
        }
    },
    "1180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1180",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1180",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-33.png",
            "scheme": "https"
        }
    },
    "170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #170",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "170",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-15.png",
            "scheme": "https"
        }
    },
    "541": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #541",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "541",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-28.png",
            "scheme": "https"
        }
    },
    "1130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1130",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1130",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-37.png",
            "scheme": "https"
        }
    },
    "1109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1109",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1109",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-1.png",
            "scheme": "https"
        }
    },
    "1160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1160",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1160",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-41.png",
            "scheme": "https"
        }
    },
    "1671": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1671",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1671",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-7.png",
            "scheme": "https"
        }
    },
    "1775": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1775",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1775",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-43.png",
            "scheme": "https"
        }
    },
    "124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #124",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "124",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-9.png",
            "scheme": "https"
        }
    },
    "136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #136",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "136",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-20.png",
            "scheme": "https"
        }
    },
    "1684": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1684",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1684",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-24.png",
            "scheme": "https"
        }
    },
    "689": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #689",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "689",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-31.png",
            "scheme": "https"
        }
    },
    "1659": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1659",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1659",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-8.png",
            "scheme": "https"
        }
    },
    "2124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2124",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2124",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-30.png",
            "scheme": "https"
        }
    },
    "1834": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1834",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1834",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-33.png",
            "scheme": "https"
        }
    },
    "1519": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1519",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1519",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-45.png",
            "scheme": "https"
        }
    },
    "2175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2175",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "2175",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-7.png",
            "scheme": "https"
        }
    },
    "1335": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1335",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1335",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-35.png",
            "scheme": "https"
        }
    },
    "557": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #557",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "557",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-21.png",
            "scheme": "https"
        }
    },
    "1177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1177",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1177",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-30.png",
            "scheme": "https"
        }
    },
    "2089": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2089",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "2089",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-20.png",
            "scheme": "https"
        }
    },
    "380": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #380",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "380",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-41.png",
            "scheme": "https"
        }
    },
    "250": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #250",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "250",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-43.png",
            "scheme": "https"
        }
    },
    "1190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1190",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1190",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-37.png",
            "scheme": "https"
        }
    },
    "454": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #454",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "454",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-28.png",
            "scheme": "https"
        }
    },
    "1224": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1224",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1224",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-46.png",
            "scheme": "https"
        }
    },
    "890": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #890",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "890",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-31.png",
            "scheme": "https"
        }
    },
    "1118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1118",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1118",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-44.png",
            "scheme": "https"
        }
    },
    "2084": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2084",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2084",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-37.png",
            "scheme": "https"
        }
    },
    "1856": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1856",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1856",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-28.png",
            "scheme": "https"
        }
    },
    "425": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #425",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "425",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-20.png",
            "scheme": "https"
        }
    },
    "424": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #424",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "424",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-22.png",
            "scheme": "https"
        }
    },
    "379": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #379",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "379",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-32.png",
            "scheme": "https"
        }
    },
    "1256": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1256",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1256",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-1.png",
            "scheme": "https"
        }
    },
    "2024": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2024",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "2024",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-30.png",
            "scheme": "https"
        }
    },
    "1868": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1868",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1868",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-38.png",
            "scheme": "https"
        }
    },
    "1705": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1705",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1705",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-46.png",
            "scheme": "https"
        }
    },
    "346": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #346",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "346",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-6.png",
            "scheme": "https"
        }
    },
    "1877": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1877",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1877",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-38.png",
            "scheme": "https"
        }
    },
    "1801": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1801",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1801",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-9.png",
            "scheme": "https"
        }
    },
    "1602": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1602",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1602",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-17.png",
            "scheme": "https"
        }
    },
    "1668": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1668",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1668",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-10.png",
            "scheme": "https"
        }
    },
    "376": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #376",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "376",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-9.png",
            "scheme": "https"
        }
    },
    "337": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #337",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "337",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-14.png",
            "scheme": "https"
        }
    },
    "267": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #267",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "267",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-35.png",
            "scheme": "https"
        }
    },
    "1380": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1380",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1380",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-1.png",
            "scheme": "https"
        }
    },
    "686": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #686",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "686",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-32.png",
            "scheme": "https"
        }
    },
    "2196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2196",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "2196",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-15.png",
            "scheme": "https"
        }
    },
    "156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #156",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "156",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-43.png",
            "scheme": "https"
        }
    },
    "742": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #742",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "742",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-11.png",
            "scheme": "https"
        }
    },
    "344": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #344",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "344",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-6.png",
            "scheme": "https"
        }
    },
    "1846": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1846",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1846",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-1.png",
            "scheme": "https"
        }
    },
    "900": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #900",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "900",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-25.png",
            "scheme": "https"
        }
    },
    "1944": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1944",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1944",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-29.png",
            "scheme": "https"
        }
    },
    "1402": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1402",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1402",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-17.png",
            "scheme": "https"
        }
    },
    "1981": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1981",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1981",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-31.png",
            "scheme": "https"
        }
    },
    "434": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #434",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "434",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-37.png",
            "scheme": "https"
        }
    },
    "1872": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1872",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1872",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-43.png",
            "scheme": "https"
        }
    },
    "518": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #518",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "518",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-5.png",
            "scheme": "https"
        }
    },
    "254": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #254",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "254",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-39.png",
            "scheme": "https"
        }
    },
    "792": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #792",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "792",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-39.png",
            "scheme": "https"
        }
    },
    "1412": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1412",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1412",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-11.png",
            "scheme": "https"
        }
    },
    "1745": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1745",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1745",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-18.png",
            "scheme": "https"
        }
    },
    "507": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #507",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "507",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-27.png",
            "scheme": "https"
        }
    },
    "603": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #603",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "603",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-44.png",
            "scheme": "https"
        }
    },
    "1652": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1652",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1652",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-12.png",
            "scheme": "https"
        }
    },
    "2092": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2092",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "2092",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-12.png",
            "scheme": "https"
        }
    },
    "1273": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1273",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1273",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-16.png",
            "scheme": "https"
        }
    },
    "1485": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1485",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1485",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-5.png",
            "scheme": "https"
        }
    },
    "1135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1135",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1135",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-5.png",
            "scheme": "https"
        }
    },
    "650": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #650",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "650",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-8.png",
            "scheme": "https"
        }
    },
    "849": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #849",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "849",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-32.png",
            "scheme": "https"
        }
    },
    "787": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #787",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "787",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-21.png",
            "scheme": "https"
        }
    },
    "1960": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1960",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1960",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-19.png",
            "scheme": "https"
        }
    },
    "993": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #993",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "993",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-11.png",
            "scheme": "https"
        }
    },
    "1119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1119",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1119",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-31.png",
            "scheme": "https"
        }
    },
    "355": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #355",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "355",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-32.png",
            "scheme": "https"
        }
    },
    "152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #152",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "152",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-14.png",
            "scheme": "https"
        }
    },
    "1800": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1800",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1800",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-21.png",
            "scheme": "https"
        }
    },
    "1528": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1528",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1528",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-26.png",
            "scheme": "https"
        }
    },
    "1693": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1693",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1693",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-40.png",
            "scheme": "https"
        }
    },
    "874": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #874",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "874",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-18.png",
            "scheme": "https"
        }
    },
    "730": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #730",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "730",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-34.png",
            "scheme": "https"
        }
    },
    "937": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #937",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "937",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-28.png",
            "scheme": "https"
        }
    },
    "1024": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1024",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1024",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-25.png",
            "scheme": "https"
        }
    },
    "634": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #634",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "634",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-12.png",
            "scheme": "https"
        }
    },
    "477": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #477",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "477",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-27.png",
            "scheme": "https"
        }
    },
    "1929": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1929",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1929",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-21.png",
            "scheme": "https"
        }
    },
    "2028": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2028",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2028",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-19.png",
            "scheme": "https"
        }
    },
    "328": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #328",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "328",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-15.png",
            "scheme": "https"
        }
    },
    "564": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #564",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "564",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-12.png",
            "scheme": "https"
        }
    },
    "850": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #850",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "850",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-3.png",
            "scheme": "https"
        }
    },
    "738": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #738",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "738",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-42.png",
            "scheme": "https"
        }
    },
    "1814": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1814",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1814",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-5.png",
            "scheme": "https"
        }
    },
    "2202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2202",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "2202",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-2.png",
            "scheme": "https"
        }
    },
    "593": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #593",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "593",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-27.png",
            "scheme": "https"
        }
    },
    "384": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #384",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "384",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-3.png",
            "scheme": "https"
        }
    },
    "870": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #870",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "870",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-40.png",
            "scheme": "https"
        }
    },
    "669": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #669",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "669",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-35.png",
            "scheme": "https"
        }
    },
    "1025": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1025",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1025",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-8.png",
            "scheme": "https"
        }
    },
    "1778": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1778",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1778",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-5.png",
            "scheme": "https"
        }
    },
    "410": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #410",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "410",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-11.png",
            "scheme": "https"
        }
    },
    "1342": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1342",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1342",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-24.png",
            "scheme": "https"
        }
    },
    "287": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #287",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "287",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-25.png",
            "scheme": "https"
        }
    },
    "834": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #834",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "834",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-19.png",
            "scheme": "https"
        }
    },
    "119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #119",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "119",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-12.png",
            "scheme": "https"
        }
    },
    "2201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2201",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "2201",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-30.png",
            "scheme": "https"
        }
    },
    "1516": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1516",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1516",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-16.png",
            "scheme": "https"
        }
    },
    "1731": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1731",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1731",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-15.png",
            "scheme": "https"
        }
    },
    "1568": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1568",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1568",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-28.png",
            "scheme": "https"
        }
    },
    "2100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2100",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "2100",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-30.png",
            "scheme": "https"
        }
    },
    "1748": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1748",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1748",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-28.png",
            "scheme": "https"
        }
    },
    "284": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #284",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "284",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-21.png",
            "scheme": "https"
        }
    },
    "1729": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1729",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1729",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-29.png",
            "scheme": "https"
        }
    },
    "1934": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1934",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1934",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-8.png",
            "scheme": "https"
        }
    },
    "79": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #79",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "79",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-15.png",
            "scheme": "https"
        }
    },
    "1678": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1678",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1678",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-14.png",
            "scheme": "https"
        }
    },
    "265": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #265",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "265",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-22.png",
            "scheme": "https"
        }
    },
    "268": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #268",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "268",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-5.png",
            "scheme": "https"
        }
    },
    "223": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #223",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "223",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-17.png",
            "scheme": "https"
        }
    },
    "2006": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2006",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "2006",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-26.png",
            "scheme": "https"
        }
    },
    "535": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #535",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "535",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-36.png",
            "scheme": "https"
        }
    },
    "1499": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1499",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1499",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-43.png",
            "scheme": "https"
        }
    },
    "711": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #711",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "711",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-39.png",
            "scheme": "https"
        }
    },
    "884": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #884",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "884",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-38.png",
            "scheme": "https"
        }
    },
    "1954": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1954",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1954",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-19.png",
            "scheme": "https"
        }
    },
    "1572": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1572",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1572",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-16.png",
            "scheme": "https"
        }
    },
    "2158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2158",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "2158",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-22.png",
            "scheme": "https"
        }
    },
    "1348": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1348",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1348",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-6.png",
            "scheme": "https"
        }
    },
    "1665": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1665",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1665",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-20.png",
            "scheme": "https"
        }
    },
    "1911": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1911",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1911",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-15.png",
            "scheme": "https"
        }
    },
    "1743": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1743",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1743",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-31.png",
            "scheme": "https"
        }
    },
    "1974": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1974",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1974",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-2.png",
            "scheme": "https"
        }
    },
    "17": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #17",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "17",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-34.png",
            "scheme": "https"
        }
    },
    "1346": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1346",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1346",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-21.png",
            "scheme": "https"
        }
    },
    "75": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #75",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "75",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-43.png",
            "scheme": "https"
        }
    },
    "2034": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2034",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "2034",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-33.png",
            "scheme": "https"
        }
    },
    "728": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #728",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "728",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-38.png",
            "scheme": "https"
        }
    },
    "302": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #302",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "302",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-12.png",
            "scheme": "https"
        }
    },
    "1211": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1211",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1211",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-5.png",
            "scheme": "https"
        }
    },
    "1768": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1768",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1768",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-25.png",
            "scheme": "https"
        }
    },
    "2121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2121",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "2121",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-40.png",
            "scheme": "https"
        }
    },
    "445": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #445",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "445",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-4.png",
            "scheme": "https"
        }
    },
    "599": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #599",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "599",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-31.png",
            "scheme": "https"
        }
    },
    "660": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #660",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "660",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-23.png",
            "scheme": "https"
        }
    },
    "1235": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1235",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1235",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-9.png",
            "scheme": "https"
        }
    },
    "1488": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1488",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1488",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-17.png",
            "scheme": "https"
        }
    },
    "1689": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1689",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1689",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-37.png",
            "scheme": "https"
        }
    },
    "1076": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1076",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1076",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-11.png",
            "scheme": "https"
        }
    },
    "1950": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1950",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1950",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-18.png",
            "scheme": "https"
        }
    },
    "709": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #709",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "709",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-3.png",
            "scheme": "https"
        }
    },
    "981": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #981",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "981",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-31.png",
            "scheme": "https"
        }
    },
    "1090": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1090",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1090",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-24.png",
            "scheme": "https"
        }
    },
    "367": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #367",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "367",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-13.png",
            "scheme": "https"
        }
    },
    "899": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #899",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "899",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-46.png",
            "scheme": "https"
        }
    },
    "1293": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1293",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1293",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-19.png",
            "scheme": "https"
        }
    },
    "813": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #813",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "813",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-15.png",
            "scheme": "https"
        }
    },
    "1680": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1680",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1680",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-11.png",
            "scheme": "https"
        }
    },
    "1162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1162",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1162",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-44.png",
            "scheme": "https"
        }
    },
    "239": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #239",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "239",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-39.png",
            "scheme": "https"
        }
    },
    "1151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1151",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1151",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-36.png",
            "scheme": "https"
        }
    },
    "1146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1146",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1146",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-45.png",
            "scheme": "https"
        }
    },
    "684": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #684",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "684",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-5.png",
            "scheme": "https"
        }
    },
    "1176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1176",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1176",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-34.png",
            "scheme": "https"
        }
    },
    "357": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #357",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "357",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-20.png",
            "scheme": "https"
        }
    },
    "182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #182",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "182",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-10.png",
            "scheme": "https"
        }
    },
    "121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #121",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "121",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-8.png",
            "scheme": "https"
        }
    },
    "1897": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1897",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1897",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-15.png",
            "scheme": "https"
        }
    },
    "784": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #784",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "784",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-5.png",
            "scheme": "https"
        }
    },
    "2188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2188",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "2188",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-40.png",
            "scheme": "https"
        }
    },
    "561": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #561",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "561",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-42.png",
            "scheme": "https"
        }
    },
    "2070": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2070",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "2070",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-9.png",
            "scheme": "https"
        }
    },
    "403": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #403",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "403",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-27.png",
            "scheme": "https"
        }
    },
    "876": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #876",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "876",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-7.png",
            "scheme": "https"
        }
    },
    "745": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #745",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "745",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-2.png",
            "scheme": "https"
        }
    },
    "1865": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1865",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1865",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-3.png",
            "scheme": "https"
        }
    },
    "919": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #919",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "919",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-27.png",
            "scheme": "https"
        }
    },
    "174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #174",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "174",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-3.png",
            "scheme": "https"
        }
    },
    "91": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #91",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "91",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-4.png",
            "scheme": "https"
        }
    },
    "667": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #667",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "667",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-30.png",
            "scheme": "https"
        }
    },
    "1354": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1354",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1354",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-16.png",
            "scheme": "https"
        }
    },
    "64": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #64",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "64",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-18.png",
            "scheme": "https"
        }
    },
    "1079": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1079",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1079",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-37.png",
            "scheme": "https"
        }
    },
    "1920": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1920",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1920",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-2.png",
            "scheme": "https"
        }
    },
    "1289": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1289",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1289",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-14.png",
            "scheme": "https"
        }
    },
    "314": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #314",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "314",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-19.png",
            "scheme": "https"
        }
    },
    "114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #114",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "114",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-38.png",
            "scheme": "https"
        }
    },
    "1628": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1628",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1628",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-39.png",
            "scheme": "https"
        }
    },
    "811": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #811",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "811",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-42.png",
            "scheme": "https"
        }
    },
    "142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #142",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "142",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-35.png",
            "scheme": "https"
        }
    },
    "1430": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1430",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1430",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-36.png",
            "scheme": "https"
        }
    },
    "1760": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1760",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1760",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-4.png",
            "scheme": "https"
        }
    },
    "2010": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2010",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "2010",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-30.png",
            "scheme": "https"
        }
    },
    "36": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #36",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "36",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-20.png",
            "scheme": "https"
        }
    },
    "446": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #446",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "446",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-28.png",
            "scheme": "https"
        }
    },
    "1280": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1280",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1280",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-15.png",
            "scheme": "https"
        }
    },
    "1726": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1726",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1726",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-45.png",
            "scheme": "https"
        }
    },
    "1397": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1397",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1397",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-41.png",
            "scheme": "https"
        }
    },
    "1212": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1212",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1212",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-2.png",
            "scheme": "https"
        }
    },
    "411": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #411",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "411",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-1.png",
            "scheme": "https"
        }
    },
    "1709": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1709",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1709",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-29.png",
            "scheme": "https"
        }
    },
    "606": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #606",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "606",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-46.png",
            "scheme": "https"
        }
    },
    "1889": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1889",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1889",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-31.png",
            "scheme": "https"
        }
    },
    "14": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #14",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "14",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-1.png",
            "scheme": "https"
        }
    },
    "227": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #227",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "227",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-28.png",
            "scheme": "https"
        }
    },
    "1520": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1520",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1520",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-42.png",
            "scheme": "https"
        }
    },
    "231": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #231",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "231",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-10.png",
            "scheme": "https"
        }
    },
    "2037": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2037",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "2037",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-14.png",
            "scheme": "https"
        }
    },
    "1041": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1041",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1041",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-42.png",
            "scheme": "https"
        }
    },
    "503": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #503",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "503",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-28.png",
            "scheme": "https"
        }
    },
    "880": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #880",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "880",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-31.png",
            "scheme": "https"
        }
    },
    "1469": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1469",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1469",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-45.png",
            "scheme": "https"
        }
    },
    "1980": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1980",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1980",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-7.png",
            "scheme": "https"
        }
    },
    "1029": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1029",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1029",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-31.png",
            "scheme": "https"
        }
    },
    "1209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1209",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1209",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-37.png",
            "scheme": "https"
        }
    },
    "450": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #450",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "450",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-21.png",
            "scheme": "https"
        }
    },
    "2017": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2017",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "2017",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-24.png",
            "scheme": "https"
        }
    },
    "1023": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1023",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1023",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-30.png",
            "scheme": "https"
        }
    },
    "369": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #369",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "369",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-31.png",
            "scheme": "https"
        }
    },
    "457": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #457",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "457",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-10.png",
            "scheme": "https"
        }
    },
    "1914": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1914",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1914",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-28.png",
            "scheme": "https"
        }
    },
    "117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #117",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "117",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-27.png",
            "scheme": "https"
        }
    },
    "1421": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1421",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1421",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-28.png",
            "scheme": "https"
        }
    },
    "627": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #627",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "627",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-39.png",
            "scheme": "https"
        }
    },
    "841": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #841",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "841",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-29.png",
            "scheme": "https"
        }
    },
    "2199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2199",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2199",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-39.png",
            "scheme": "https"
        }
    },
    "623": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #623",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "623",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-47.png",
            "scheme": "https"
        }
    },
    "1573": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1573",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1573",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-45.png",
            "scheme": "https"
        }
    },
    "1204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1204",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1204",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-43.png",
            "scheme": "https"
        }
    },
    "1672": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1672",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1672",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-17.png",
            "scheme": "https"
        }
    },
    "1404": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1404",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1404",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-6.png",
            "scheme": "https"
        }
    },
    "1817": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1817",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1817",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-19.png",
            "scheme": "https"
        }
    },
    "1777": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1777",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1777",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-16.png",
            "scheme": "https"
        }
    },
    "2062": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2062",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "2062",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-26.png",
            "scheme": "https"
        }
    },
    "211": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #211",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "211",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-27.png",
            "scheme": "https"
        }
    },
    "288": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #288",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "288",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-38.png",
            "scheme": "https"
        }
    },
    "2018": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2018",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "2018",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-6.png",
            "scheme": "https"
        }
    },
    "237": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #237",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "237",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-2.png",
            "scheme": "https"
        }
    },
    "1117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1117",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1117",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-34.png",
            "scheme": "https"
        }
    },
    "1096": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1096",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1096",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-8.png",
            "scheme": "https"
        }
    },
    "1789": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1789",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1789",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-24.png",
            "scheme": "https"
        }
    },
    "1016": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1016",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1016",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-45.png",
            "scheme": "https"
        }
    },
    "1165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1165",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1165",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-44.png",
            "scheme": "https"
        }
    },
    "1839": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1839",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1839",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-1.png",
            "scheme": "https"
        }
    },
    "1866": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1866",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1866",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-42.png",
            "scheme": "https"
        }
    },
    "2145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2145",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "2145",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-40.png",
            "scheme": "https"
        }
    },
    "1999": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1999",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1999",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-37.png",
            "scheme": "https"
        }
    },
    "764": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #764",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "764",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-11.png",
            "scheme": "https"
        }
    },
    "508": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #508",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "508",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-43.png",
            "scheme": "https"
        }
    },
    "1442": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1442",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1442",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-4.png",
            "scheme": "https"
        }
    },
    "1562": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1562",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1562",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-20.png",
            "scheme": "https"
        }
    },
    "1141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1141",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1141",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-47.png",
            "scheme": "https"
        }
    },
    "2143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2143",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "2143",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-22.png",
            "scheme": "https"
        }
    },
    "1841": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1841",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1841",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-26.png",
            "scheme": "https"
        }
    },
    "973": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #973",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "973",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-4.png",
            "scheme": "https"
        }
    },
    "1966": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1966",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1966",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-29.png",
            "scheme": "https"
        }
    },
    "1409": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1409",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1409",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-27.png",
            "scheme": "https"
        }
    },
    "1632": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1632",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1632",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-42.png",
            "scheme": "https"
        }
    },
    "1420": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1420",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1420",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-7.png",
            "scheme": "https"
        }
    },
    "1901": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1901",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1901",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-46.png",
            "scheme": "https"
        }
    },
    "1390": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1390",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1390",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-30.png",
            "scheme": "https"
        }
    },
    "290": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #290",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "290",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-35.png",
            "scheme": "https"
        }
    },
    "127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #127",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "127",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-36.png",
            "scheme": "https"
        }
    },
    "1598": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1598",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1598",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-30.png",
            "scheme": "https"
        }
    },
    "585": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #585",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "585",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-43.png",
            "scheme": "https"
        }
    },
    "1313": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1313",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1313",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-8.png",
            "scheme": "https"
        }
    },
    "740": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #740",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "740",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-13.png",
            "scheme": "https"
        }
    },
    "779": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #779",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "779",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-40.png",
            "scheme": "https"
        }
    },
    "1308": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1308",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1308",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-31.png",
            "scheme": "https"
        }
    },
    "1433": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1433",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1433",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-16.png",
            "scheme": "https"
        }
    },
    "604": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #604",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "604",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-2.png",
            "scheme": "https"
        }
    },
    "2063": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2063",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "2063",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-33.png",
            "scheme": "https"
        }
    },
    "2168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2168",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2168",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-28.png",
            "scheme": "https"
        }
    },
    "377": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #377",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "377",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-15.png",
            "scheme": "https"
        }
    },
    "88": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #88",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "88",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-27.png",
            "scheme": "https"
        }
    },
    "1315": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1315",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1315",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-6.png",
            "scheme": "https"
        }
    },
    "928": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #928",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "928",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-26.png",
            "scheme": "https"
        }
    },
    "1199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1199",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1199",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-12.png",
            "scheme": "https"
        }
    },
    "795": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #795",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "795",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-16.png",
            "scheme": "https"
        }
    },
    "1155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1155",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1155",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-32.png",
            "scheme": "https"
        }
    },
    "1047": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1047",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1047",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-14.png",
            "scheme": "https"
        }
    },
    "1503": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1503",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1503",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-36.png",
            "scheme": "https"
        }
    },
    "1148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1148",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1148",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-13.png",
            "scheme": "https"
        }
    },
    "1910": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1910",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1910",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-46.png",
            "scheme": "https"
        }
    },
    "1557": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1557",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1557",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-45.png",
            "scheme": "https"
        }
    },
    "513": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #513",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "513",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-45.png",
            "scheme": "https"
        }
    },
    "1071": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1071",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1071",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-37.png",
            "scheme": "https"
        }
    },
    "2045": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2045",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "2045",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-3.png",
            "scheme": "https"
        }
    },
    "93": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #93",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "93",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-6.png",
            "scheme": "https"
        }
    },
    "1988": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1988",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1988",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-43.png",
            "scheme": "https"
        }
    },
    "2038": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2038",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "2038",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-47.png",
            "scheme": "https"
        }
    },
    "1394": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1394",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1394",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-16.png",
            "scheme": "https"
        }
    },
    "938": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #938",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "938",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-15.png",
            "scheme": "https"
        }
    },
    "2179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2179",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "2179",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-21.png",
            "scheme": "https"
        }
    },
    "1586": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1586",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1586",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-28.png",
            "scheme": "https"
        }
    },
    "342": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #342",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "342",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-3.png",
            "scheme": "https"
        }
    },
    "1508": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1508",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1508",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-16.png",
            "scheme": "https"
        }
    },
    "276": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #276",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "276",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-34.png",
            "scheme": "https"
        }
    },
    "161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #161",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "161",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-14.png",
            "scheme": "https"
        }
    },
    "281": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #281",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "281",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-47.png",
            "scheme": "https"
        }
    },
    "598": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #598",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "598",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-41.png",
            "scheme": "https"
        }
    },
    "1978": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1978",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1978",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-10.png",
            "scheme": "https"
        }
    },
    "2107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2107",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "2107",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-25.png",
            "scheme": "https"
        }
    },
    "216": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #216",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "216",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-35.png",
            "scheme": "https"
        }
    },
    "1554": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1554",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1554",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-14.png",
            "scheme": "https"
        }
    },
    "765": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #765",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "765",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-1.png",
            "scheme": "https"
        }
    },
    "1340": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1340",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1340",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-26.png",
            "scheme": "https"
        }
    },
    "733": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #733",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "733",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-1.png",
            "scheme": "https"
        }
    },
    "1650": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1650",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1650",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-28.png",
            "scheme": "https"
        }
    },
    "1455": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1455",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1455",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-25.png",
            "scheme": "https"
        }
    },
    "1074": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1074",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1074",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-38.png",
            "scheme": "https"
        }
    },
    "814": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #814",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "814",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-1.png",
            "scheme": "https"
        }
    },
    "605": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #605",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "605",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-27.png",
            "scheme": "https"
        }
    },
    "654": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #654",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "654",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-29.png",
            "scheme": "https"
        }
    },
    "271": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #271",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "271",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-41.png",
            "scheme": "https"
        }
    },
    "534": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #534",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "534",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-35.png",
            "scheme": "https"
        }
    },
    "2022": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2022",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "2022",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-45.png",
            "scheme": "https"
        }
    },
    "676": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #676",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "676",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-36.png",
            "scheme": "https"
        }
    },
    "2060": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2060",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "2060",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-22.png",
            "scheme": "https"
        }
    },
    "1296": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1296",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1296",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-22.png",
            "scheme": "https"
        }
    },
    "2189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2189",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "2189",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-13.png",
            "scheme": "https"
        }
    },
    "2141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2141",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2141",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-42.png",
            "scheme": "https"
        }
    },
    "1500": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1500",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1500",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-32.png",
            "scheme": "https"
        }
    },
    "962": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #962",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "962",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-46.png",
            "scheme": "https"
        }
    },
    "49": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #49",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "49",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-42.png",
            "scheme": "https"
        }
    },
    "24": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #24",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "24",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-4.png",
            "scheme": "https"
        }
    },
    "2156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2156",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2156",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-32.png",
            "scheme": "https"
        }
    },
    "1757": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1757",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1757",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-35.png",
            "scheme": "https"
        }
    },
    "277": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #277",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "277",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-4.png",
            "scheme": "https"
        }
    },
    "1736": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1736",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1736",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-25.png",
            "scheme": "https"
        }
    },
    "799": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #799",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "799",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-39.png",
            "scheme": "https"
        }
    },
    "1618": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1618",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1618",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-35.png",
            "scheme": "https"
        }
    },
    "426": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #426",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "426",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-32.png",
            "scheme": "https"
        }
    },
    "1727": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1727",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1727",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-21.png",
            "scheme": "https"
        }
    },
    "759": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #759",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "759",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-29.png",
            "scheme": "https"
        }
    },
    "1840": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1840",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1840",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-32.png",
            "scheme": "https"
        }
    },
    "498": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #498",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "498",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-11.png",
            "scheme": "https"
        }
    },
    "798": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #798",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "798",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-21.png",
            "scheme": "https"
        }
    },
    "1321": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1321",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1321",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-26.png",
            "scheme": "https"
        }
    },
    "401": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #401",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "401",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-2.png",
            "scheme": "https"
        }
    },
    "635": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #635",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "635",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-24.png",
            "scheme": "https"
        }
    },
    "1940": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1940",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1940",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-30.png",
            "scheme": "https"
        }
    },
    "1957": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1957",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1957",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-37.png",
            "scheme": "https"
        }
    },
    "2021": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2021",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "2021",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-19.png",
            "scheme": "https"
        }
    },
    "461": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #461",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "461",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-3.png",
            "scheme": "https"
        }
    },
    "491": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #491",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "491",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-25.png",
            "scheme": "https"
        }
    },
    "2087": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2087",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "2087",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-47.png",
            "scheme": "https"
        }
    },
    "1131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1131",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1131",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-22.png",
            "scheme": "https"
        }
    },
    "1604": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1604",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1604",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-10.png",
            "scheme": "https"
        }
    },
    "1066": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1066",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1066",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-39.png",
            "scheme": "https"
        }
    },
    "463": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #463",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "463",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-37.png",
            "scheme": "https"
        }
    },
    "204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #204",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "204",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-44.png",
            "scheme": "https"
        }
    },
    "483": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #483",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "483",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-6.png",
            "scheme": "https"
        }
    },
    "441": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #441",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "441",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-21.png",
            "scheme": "https"
        }
    },
    "1663": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1663",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1663",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-14.png",
            "scheme": "https"
        }
    },
    "1885": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1885",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1885",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-2.png",
            "scheme": "https"
        }
    },
    "2025": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2025",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "2025",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-28.png",
            "scheme": "https"
        }
    },
    "1682": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1682",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1682",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-32.png",
            "scheme": "https"
        }
    },
    "695": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #695",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "695",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-31.png",
            "scheme": "https"
        }
    },
    "747": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #747",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "747",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-7.png",
            "scheme": "https"
        }
    },
    "1636": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1636",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1636",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-18.png",
            "scheme": "https"
        }
    },
    "1185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1185",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1185",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-34.png",
            "scheme": "https"
        }
    },
    "1144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1144",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1144",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-47.png",
            "scheme": "https"
        }
    },
    "1123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1123",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1123",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-20.png",
            "scheme": "https"
        }
    },
    "52": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #52",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "52",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-31.png",
            "scheme": "https"
        }
    },
    "1349": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1349",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1349",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-22.png",
            "scheme": "https"
        }
    },
    "218": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #218",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "218",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-37.png",
            "scheme": "https"
        }
    },
    "1425": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1425",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1425",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-26.png",
            "scheme": "https"
        }
    },
    "542": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #542",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "542",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-45.png",
            "scheme": "https"
        }
    },
    "2032": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2032",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2032",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-14.png",
            "scheme": "https"
        }
    },
    "157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #157",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "157",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-26.png",
            "scheme": "https"
        }
    },
    "1986": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1986",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1986",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-44.png",
            "scheme": "https"
        }
    },
    "74": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #74",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "74",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-45.png",
            "scheme": "https"
        }
    },
    "1347": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1347",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1347",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-19.png",
            "scheme": "https"
        }
    },
    "207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #207",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "207",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-15.png",
            "scheme": "https"
        }
    },
    "394": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #394",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "394",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-11.png",
            "scheme": "https"
        }
    },
    "744": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #744",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "744",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-8.png",
            "scheme": "https"
        }
    },
    "1997": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1997",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1997",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-41.png",
            "scheme": "https"
        }
    },
    "641": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #641",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "641",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-5.png",
            "scheme": "https"
        }
    },
    "7": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #7",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "7",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-27.png",
            "scheme": "https"
        }
    },
    "1213": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1213",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1213",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-4.png",
            "scheme": "https"
        }
    },
    "1134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1134",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1134",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-38.png",
            "scheme": "https"
        }
    },
    "1526": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1526",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1526",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-3.png",
            "scheme": "https"
        }
    },
    "125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #125",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "125",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-41.png",
            "scheme": "https"
        }
    },
    "2066": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2066",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "2066",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-35.png",
            "scheme": "https"
        }
    },
    "1751": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1751",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1751",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-26.png",
            "scheme": "https"
        }
    },
    "1536": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1536",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1536",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-18.png",
            "scheme": "https"
        }
    },
    "1178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1178",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1178",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-36.png",
            "scheme": "https"
        }
    },
    "1669": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1669",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1669",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-35.png",
            "scheme": "https"
        }
    },
    "1493": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1493",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1493",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-10.png",
            "scheme": "https"
        }
    },
    "951": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #951",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "951",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-5.png",
            "scheme": "https"
        }
    },
    "1578": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1578",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1578",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-42.png",
            "scheme": "https"
        }
    },
    "809": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #809",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "809",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-3.png",
            "scheme": "https"
        }
    },
    "1534": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1534",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1534",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-28.png",
            "scheme": "https"
        }
    },
    "30": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #30",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "30",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-25.png",
            "scheme": "https"
        }
    },
    "313": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #313",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "313",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-12.png",
            "scheme": "https"
        }
    },
    "502": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #502",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "502",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-46.png",
            "scheme": "https"
        }
    },
    "1396": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1396",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1396",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-42.png",
            "scheme": "https"
        }
    },
    "1697": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1697",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1697",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-1.png",
            "scheme": "https"
        }
    },
    "256": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #256",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "256",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-1.png",
            "scheme": "https"
        }
    },
    "823": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #823",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "823",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-32.png",
            "scheme": "https"
        }
    },
    "571": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #571",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "571",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-45.png",
            "scheme": "https"
        }
    },
    "1101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1101",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1101",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-16.png",
            "scheme": "https"
        }
    },
    "770": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #770",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "770",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-9.png",
            "scheme": "https"
        }
    },
    "970": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #970",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "970",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-36.png",
            "scheme": "https"
        }
    },
    "2161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2161",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "2161",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-13.png",
            "scheme": "https"
        }
    },
    "903": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #903",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "903",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-30.png",
            "scheme": "https"
        }
    },
    "293": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #293",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "293",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-36.png",
            "scheme": "https"
        }
    },
    "1186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1186",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1186",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-12.png",
            "scheme": "https"
        }
    },
    "286": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #286",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "286",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-21.png",
            "scheme": "https"
        }
    },
    "2155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2155",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2155",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-20.png",
            "scheme": "https"
        }
    },
    "2046": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2046",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "2046",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-44.png",
            "scheme": "https"
        }
    },
    "1624": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1624",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1624",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-23.png",
            "scheme": "https"
        }
    },
    "2003": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2003",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "2003",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-25.png",
            "scheme": "https"
        }
    },
    "1366": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1366",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1366",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-14.png",
            "scheme": "https"
        }
    },
    "1714": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1714",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1714",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-41.png",
            "scheme": "https"
        }
    },
    "299": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #299",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "299",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-9.png",
            "scheme": "https"
        }
    },
    "902": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #902",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "902",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-12.png",
            "scheme": "https"
        }
    },
    "1514": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1514",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1514",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-34.png",
            "scheme": "https"
        }
    },
    "1925": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1925",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1925",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-32.png",
            "scheme": "https"
        }
    },
    "1888": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1888",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1888",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-34.png",
            "scheme": "https"
        }
    },
    "1698": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1698",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1698",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-15.png",
            "scheme": "https"
        }
    },
    "1611": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1611",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1611",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-21.png",
            "scheme": "https"
        }
    },
    "451": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #451",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "451",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-42.png",
            "scheme": "https"
        }
    },
    "522": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #522",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "522",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-42.png",
            "scheme": "https"
        }
    },
    "1701": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1701",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1701",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-17.png",
            "scheme": "https"
        }
    },
    "2149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2149",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "2149",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-17.png",
            "scheme": "https"
        }
    },
    "713": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #713",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "713",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-6.png",
            "scheme": "https"
        }
    },
    "1310": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1310",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1310",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-14.png",
            "scheme": "https"
        }
    },
    "734": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #734",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "734",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-38.png",
            "scheme": "https"
        }
    },
    "1994": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1994",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1994",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-39.png",
            "scheme": "https"
        }
    },
    "189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #189",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "189",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-25.png",
            "scheme": "https"
        }
    },
    "1133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1133",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1133",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-40.png",
            "scheme": "https"
        }
    },
    "708": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #708",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "708",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-3.png",
            "scheme": "https"
        }
    },
    "1728": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1728",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1728",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-8.png",
            "scheme": "https"
        }
    },
    "648": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #648",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "648",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-34.png",
            "scheme": "https"
        }
    },
    "1620": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1620",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1620",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-29.png",
            "scheme": "https"
        }
    },
    "2157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2157",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "2157",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-26.png",
            "scheme": "https"
        }
    },
    "69": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #69",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "69",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-3.png",
            "scheme": "https"
        }
    },
    "1063": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1063",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1063",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-4.png",
            "scheme": "https"
        }
    },
    "442": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #442",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "442",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-32.png",
            "scheme": "https"
        }
    },
    "558": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #558",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "558",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-29.png",
            "scheme": "https"
        }
    },
    "1314": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1314",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1314",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-15.png",
            "scheme": "https"
        }
    },
    "737": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #737",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "737",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-19.png",
            "scheme": "https"
        }
    },
    "1794": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1794",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1794",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-15.png",
            "scheme": "https"
        }
    },
    "1582": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1582",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1582",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-46.png",
            "scheme": "https"
        }
    },
    "1535": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1535",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1535",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-29.png",
            "scheme": "https"
        }
    },
    "766": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #766",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "766",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-23.png",
            "scheme": "https"
        }
    },
    "1679": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1679",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1679",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-6.png",
            "scheme": "https"
        }
    },
    "1869": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1869",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1869",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-22.png",
            "scheme": "https"
        }
    },
    "586": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #586",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "586",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-31.png",
            "scheme": "https"
        }
    },
    "1257": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1257",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1257",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-32.png",
            "scheme": "https"
        }
    },
    "444": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #444",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "444",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-26.png",
            "scheme": "https"
        }
    },
    "1945": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1945",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1945",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-36.png",
            "scheme": "https"
        }
    },
    "1049": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1049",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1049",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-18.png",
            "scheme": "https"
        }
    },
    "1439": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1439",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1439",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-35.png",
            "scheme": "https"
        }
    },
    "1089": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1089",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1089",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-18.png",
            "scheme": "https"
        }
    },
    "1718": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1718",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1718",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-21.png",
            "scheme": "https"
        }
    },
    "1069": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1069",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1069",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-5.png",
            "scheme": "https"
        }
    },
    "565": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #565",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "565",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-5.png",
            "scheme": "https"
        }
    },
    "1245": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1245",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1245",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-2.png",
            "scheme": "https"
        }
    },
    "404": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #404",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "404",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-14.png",
            "scheme": "https"
        }
    },
    "1034": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1034",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1034",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-8.png",
            "scheme": "https"
        }
    },
    "496": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #496",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "496",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-46.png",
            "scheme": "https"
        }
    },
    "280": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #280",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "280",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-3.png",
            "scheme": "https"
        }
    },
    "1495": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1495",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1495",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-2.png",
            "scheme": "https"
        }
    },
    "778": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #778",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "778",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-36.png",
            "scheme": "https"
        }
    },
    "148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #148",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "148",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-47.png",
            "scheme": "https"
        }
    },
    "574": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #574",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "574",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-24.png",
            "scheme": "https"
        }
    },
    "1641": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1641",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1641",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-5.png",
            "scheme": "https"
        }
    },
    "944": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #944",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "944",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-38.png",
            "scheme": "https"
        }
    },
    "2097": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2097",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2097",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-37.png",
            "scheme": "https"
        }
    },
    "343": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #343",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "343",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-34.png",
            "scheme": "https"
        }
    },
    "1389": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1389",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1389",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-9.png",
            "scheme": "https"
        }
    },
    "943": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #943",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "943",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-16.png",
            "scheme": "https"
        }
    },
    "1887": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1887",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1887",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-34.png",
            "scheme": "https"
        }
    },
    "456": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #456",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "456",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-38.png",
            "scheme": "https"
        }
    },
    "808": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #808",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "808",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-38.png",
            "scheme": "https"
        }
    },
    "703": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #703",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "703",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-12.png",
            "scheme": "https"
        }
    },
    "164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #164",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "164",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-45.png",
            "scheme": "https"
        }
    },
    "594": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #594",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "594",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-11.png",
            "scheme": "https"
        }
    },
    "2132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2132",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "2132",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-41.png",
            "scheme": "https"
        }
    },
    "285": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #285",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "285",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-39.png",
            "scheme": "https"
        }
    },
    "1476": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1476",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1476",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-28.png",
            "scheme": "https"
        }
    },
    "1913": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1913",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1913",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-10.png",
            "scheme": "https"
        }
    },
    "946": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #946",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "946",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-17.png",
            "scheme": "https"
        }
    },
    "1766": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1766",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1766",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-22.png",
            "scheme": "https"
        }
    },
    "1272": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1272",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1272",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-35.png",
            "scheme": "https"
        }
    },
    "1670": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1670",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1670",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-21.png",
            "scheme": "https"
        }
    },
    "532": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #532",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "532",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-1.png",
            "scheme": "https"
        }
    },
    "1799": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1799",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1799",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-35.png",
            "scheme": "https"
        }
    },
    "822": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #822",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "822",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-11.png",
            "scheme": "https"
        }
    },
    "939": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #939",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "939",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-13.png",
            "scheme": "https"
        }
    },
    "612": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #612",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "612",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-2.png",
            "scheme": "https"
        }
    },
    "1247": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1247",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1247",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-26.png",
            "scheme": "https"
        }
    },
    "1039": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1039",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1039",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-47.png",
            "scheme": "https"
        }
    },
    "1649": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1649",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1649",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-42.png",
            "scheme": "https"
        }
    },
    "1639": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1639",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1639",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-41.png",
            "scheme": "https"
        }
    },
    "1337": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1337",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1337",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-47.png",
            "scheme": "https"
        }
    },
    "595": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #595",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "595",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-46.png",
            "scheme": "https"
        }
    },
    "519": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #519",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "519",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-42.png",
            "scheme": "https"
        }
    },
    "431": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #431",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "431",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-44.png",
            "scheme": "https"
        }
    },
    "39": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #39",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "39",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-31.png",
            "scheme": "https"
        }
    },
    "1078": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1078",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1078",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-26.png",
            "scheme": "https"
        }
    },
    "25": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #25",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "25",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-7.png",
            "scheme": "https"
        }
    },
    "916": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #916",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "916",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-21.png",
            "scheme": "https"
        }
    },
    "1259": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1259",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1259",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-44.png",
            "scheme": "https"
        }
    },
    "1894": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1894",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1894",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-13.png",
            "scheme": "https"
        }
    },
    "768": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #768",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "768",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-1.png",
            "scheme": "https"
        }
    },
    "368": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #368",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "368",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-17.png",
            "scheme": "https"
        }
    },
    "1677": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1677",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1677",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-34.png",
            "scheme": "https"
        }
    },
    "1769": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1769",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1769",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-21.png",
            "scheme": "https"
        }
    },
    "499": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #499",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "499",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-32.png",
            "scheme": "https"
        }
    },
    "1464": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1464",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1464",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-36.png",
            "scheme": "https"
        }
    },
    "796": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #796",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "796",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-21.png",
            "scheme": "https"
        }
    },
    "2180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2180",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2180",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-28.png",
            "scheme": "https"
        }
    },
    "143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #143",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "143",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-42.png",
            "scheme": "https"
        }
    },
    "2035": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2035",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "2035",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-45.png",
            "scheme": "https"
        }
    },
    "853": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #853",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "853",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-9.png",
            "scheme": "https"
        }
    },
    "523": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #523",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "523",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-47.png",
            "scheme": "https"
        }
    },
    "563": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #563",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "563",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-22.png",
            "scheme": "https"
        }
    },
    "422": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #422",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "422",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-40.png",
            "scheme": "https"
        }
    },
    "1882": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1882",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1882",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-22.png",
            "scheme": "https"
        }
    },
    "1525": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1525",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1525",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-44.png",
            "scheme": "https"
        }
    },
    "350": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #350",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "350",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-8.png",
            "scheme": "https"
        }
    },
    "172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #172",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "172",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-16.png",
            "scheme": "https"
        }
    },
    "954": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #954",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "954",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-47.png",
            "scheme": "https"
        }
    },
    "295": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #295",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "295",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-33.png",
            "scheme": "https"
        }
    },
    "420": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #420",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "420",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-11.png",
            "scheme": "https"
        }
    },
    "1264": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1264",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1264",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-10.png",
            "scheme": "https"
        }
    },
    "1626": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1626",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1626",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-34.png",
            "scheme": "https"
        }
    },
    "1600": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1600",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1600",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-6.png",
            "scheme": "https"
        }
    },
    "1615": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1615",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1615",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-11.png",
            "scheme": "https"
        }
    },
    "643": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #643",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "643",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-24.png",
            "scheme": "https"
        }
    },
    "2023": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2023",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "2023",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-37.png",
            "scheme": "https"
        }
    },
    "1786": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1786",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1786",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-5.png",
            "scheme": "https"
        }
    },
    "85": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #85",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "85",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-4.png",
            "scheme": "https"
        }
    },
    "2075": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2075",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2075",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-13.png",
            "scheme": "https"
        }
    },
    "1506": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1506",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1506",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-23.png",
            "scheme": "https"
        }
    },
    "690": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #690",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "690",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-12.png",
            "scheme": "https"
        }
    },
    "1454": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1454",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1454",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-5.png",
            "scheme": "https"
        }
    },
    "729": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #729",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "729",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-17.png",
            "scheme": "https"
        }
    },
    "1206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1206",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1206",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-31.png",
            "scheme": "https"
        }
    },
    "783": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #783",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "783",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-44.png",
            "scheme": "https"
        }
    },
    "1277": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1277",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1277",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-34.png",
            "scheme": "https"
        }
    },
    "1384": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1384",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1384",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-43.png",
            "scheme": "https"
        }
    },
    "1511": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1511",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1511",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-32.png",
            "scheme": "https"
        }
    },
    "568": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #568",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "568",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-40.png",
            "scheme": "https"
        }
    },
    "409": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #409",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "409",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-33.png",
            "scheme": "https"
        }
    },
    "1358": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1358",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1358",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-9.png",
            "scheme": "https"
        }
    },
    "1253": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1253",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1253",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-45.png",
            "scheme": "https"
        }
    },
    "1651": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1651",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1651",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-33.png",
            "scheme": "https"
        }
    },
    "2016": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2016",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "2016",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-19.png",
            "scheme": "https"
        }
    },
    "196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #196",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "196",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-39.png",
            "scheme": "https"
        }
    },
    "195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #195",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "195",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-30.png",
            "scheme": "https"
        }
    },
    "1087": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1087",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1087",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-27.png",
            "scheme": "https"
        }
    },
    "2020": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2020",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "2020",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-13.png",
            "scheme": "https"
        }
    },
    "1416": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1416",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1416",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-13.png",
            "scheme": "https"
        }
    },
    "128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #128",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "128",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-15.png",
            "scheme": "https"
        }
    },
    "26": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #26",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "26",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-29.png",
            "scheme": "https"
        }
    },
    "997": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #997",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "997",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-33.png",
            "scheme": "https"
        }
    },
    "1905": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1905",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1905",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-25.png",
            "scheme": "https"
        }
    },
    "497": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #497",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "497",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-38.png",
            "scheme": "https"
        }
    },
    "761": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #761",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "761",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-39.png",
            "scheme": "https"
        }
    },
    "421": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #421",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "421",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-40.png",
            "scheme": "https"
        }
    },
    "756": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #756",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "756",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-25.png",
            "scheme": "https"
        }
    },
    "1091": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1091",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1091",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-4.png",
            "scheme": "https"
        }
    },
    "956": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #956",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "956",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-47.png",
            "scheme": "https"
        }
    },
    "330": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #330",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "330",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-30.png",
            "scheme": "https"
        }
    },
    "731": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #731",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "731",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-34.png",
            "scheme": "https"
        }
    },
    "1594": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1594",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1594",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-22.png",
            "scheme": "https"
        }
    },
    "2200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2200",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "2200",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-33.png",
            "scheme": "https"
        }
    },
    "977": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #977",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "977",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-6.png",
            "scheme": "https"
        }
    },
    "537": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #537",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "537",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-35.png",
            "scheme": "https"
        }
    },
    "2029": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2029",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2029",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-37.png",
            "scheme": "https"
        }
    },
    "23": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #23",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "23",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-16.png",
            "scheme": "https"
        }
    },
    "103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #103",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "103",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-34.png",
            "scheme": "https"
        }
    },
    "1143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1143",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1143",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-17.png",
            "scheme": "https"
        }
    },
    "1343": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1343",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1343",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-5.png",
            "scheme": "https"
        }
    },
    "1341": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1341",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1341",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-26.png",
            "scheme": "https"
        }
    },
    "2128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2128",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "2128",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-23.png",
            "scheme": "https"
        }
    },
    "95": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #95",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "95",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-32.png",
            "scheme": "https"
        }
    },
    "716": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #716",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "716",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-38.png",
            "scheme": "https"
        }
    },
    "115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #115",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "115",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-4.png",
            "scheme": "https"
        }
    },
    "988": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #988",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "988",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-17.png",
            "scheme": "https"
        }
    },
    "1159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1159",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1159",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-33.png",
            "scheme": "https"
        }
    },
    "1446": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1446",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1446",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-36.png",
            "scheme": "https"
        }
    },
    "349": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #349",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "349",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-19.png",
            "scheme": "https"
        }
    },
    "1050": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1050",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1050",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-40.png",
            "scheme": "https"
        }
    },
    "2115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2115",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2115",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-22.png",
            "scheme": "https"
        }
    },
    "397": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #397",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "397",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-29.png",
            "scheme": "https"
        }
    },
    "492": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #492",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "492",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-7.png",
            "scheme": "https"
        }
    },
    "1054": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1054",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1054",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-39.png",
            "scheme": "https"
        }
    },
    "2061": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2061",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "2061",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-8.png",
            "scheme": "https"
        }
    },
    "1271": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1271",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1271",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-30.png",
            "scheme": "https"
        }
    },
    "1896": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1896",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1896",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-20.png",
            "scheme": "https"
        }
    },
    "327": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #327",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "327",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-37.png",
            "scheme": "https"
        }
    },
    "1459": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1459",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1459",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-23.png",
            "scheme": "https"
        }
    },
    "590": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #590",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "590",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-22.png",
            "scheme": "https"
        }
    },
    "181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #181",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "181",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-22.png",
            "scheme": "https"
        }
    },
    "11": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #11",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "11",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-40.png",
            "scheme": "https"
        }
    },
    "948": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #948",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "948",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-25.png",
            "scheme": "https"
        }
    },
    "1724": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1724",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1724",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-43.png",
            "scheme": "https"
        }
    },
    "1392": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1392",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1392",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-22.png",
            "scheme": "https"
        }
    },
    "1058": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1058",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1058",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-37.png",
            "scheme": "https"
        }
    },
    "1073": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1073",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1073",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-36.png",
            "scheme": "https"
        }
    },
    "573": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #573",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "573",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-14.png",
            "scheme": "https"
        }
    },
    "1510": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1510",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1510",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-6.png",
            "scheme": "https"
        }
    },
    "2173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2173",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2173",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-17.png",
            "scheme": "https"
        }
    },
    "886": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #886",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "886",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-19.png",
            "scheme": "https"
        }
    },
    "1873": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1873",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1873",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-5.png",
            "scheme": "https"
        }
    },
    "861": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #861",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "861",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-24.png",
            "scheme": "https"
        }
    },
    "1466": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1466",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1466",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-11.png",
            "scheme": "https"
        }
    },
    "1658": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1658",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1658",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-6.png",
            "scheme": "https"
        }
    },
    "1740": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1740",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1740",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-4.png",
            "scheme": "https"
        }
    },
    "1585": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1585",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1585",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-12.png",
            "scheme": "https"
        }
    },
    "821": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #821",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "821",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-39.png",
            "scheme": "https"
        }
    },
    "1156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1156",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1156",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-21.png",
            "scheme": "https"
        }
    },
    "2182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2182",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2182",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-44.png",
            "scheme": "https"
        }
    },
    "2080": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2080",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "2080",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-17.png",
            "scheme": "https"
        }
    },
    "246": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #246",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "246",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-33.png",
            "scheme": "https"
        }
    },
    "865": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #865",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "865",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-42.png",
            "scheme": "https"
        }
    },
    "1875": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1875",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1875",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-1.png",
            "scheme": "https"
        }
    },
    "430": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #430",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "430",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-3.png",
            "scheme": "https"
        }
    },
    "262": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #262",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "262",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-12.png",
            "scheme": "https"
        }
    },
    "494": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #494",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "494",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-23.png",
            "scheme": "https"
        }
    },
    "1871": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1871",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1871",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-44.png",
            "scheme": "https"
        }
    },
    "2147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2147",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "2147",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-27.png",
            "scheme": "https"
        }
    },
    "888": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #888",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "888",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-6.png",
            "scheme": "https"
        }
    },
    "906": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #906",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "906",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-23.png",
            "scheme": "https"
        }
    },
    "1803": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1803",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1803",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-7.png",
            "scheme": "https"
        }
    },
    "458": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #458",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "458",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-40.png",
            "scheme": "https"
        }
    },
    "685": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #685",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "685",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-40.png",
            "scheme": "https"
        }
    },
    "109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #109",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "109",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-46.png",
            "scheme": "https"
        }
    },
    "1575": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1575",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1575",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-42.png",
            "scheme": "https"
        }
    },
    "1233": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1233",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1233",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-9.png",
            "scheme": "https"
        }
    },
    "435": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #435",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "435",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-34.png",
            "scheme": "https"
        }
    },
    "1707": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1707",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1707",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-23.png",
            "scheme": "https"
        }
    },
    "1302": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1302",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1302",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-30.png",
            "scheme": "https"
        }
    },
    "2153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2153",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "2153",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-41.png",
            "scheme": "https"
        }
    },
    "1537": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1537",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1537",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-22.png",
            "scheme": "https"
        }
    },
    "2191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2191",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "2191",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-26.png",
            "scheme": "https"
        }
    },
    "924": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #924",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "924",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-8.png",
            "scheme": "https"
        }
    },
    "309": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #309",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "309",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-9.png",
            "scheme": "https"
        }
    },
    "1937": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1937",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1937",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-23.png",
            "scheme": "https"
        }
    },
    "2044": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2044",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "2044",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-40.png",
            "scheme": "https"
        }
    },
    "363": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #363",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "363",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-13.png",
            "scheme": "https"
        }
    },
    "775": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #775",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "775",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-29.png",
            "scheme": "https"
        }
    },
    "436": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #436",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "436",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-38.png",
            "scheme": "https"
        }
    },
    "2041": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2041",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "2041",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-40.png",
            "scheme": "https"
        }
    },
    "1234": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1234",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1234",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-43.png",
            "scheme": "https"
        }
    },
    "533": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #533",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "533",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-7.png",
            "scheme": "https"
        }
    },
    "620": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #620",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "620",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-46.png",
            "scheme": "https"
        }
    },
    "752": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #752",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "752",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-23.png",
            "scheme": "https"
        }
    },
    "1445": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1445",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1445",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-9.png",
            "scheme": "https"
        }
    },
    "925": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #925",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "925",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-29.png",
            "scheme": "https"
        }
    },
    "274": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #274",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "274",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-2.png",
            "scheme": "https"
        }
    },
    "1529": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1529",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1529",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-20.png",
            "scheme": "https"
        }
    },
    "362": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #362",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "362",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-4.png",
            "scheme": "https"
        }
    },
    "2008": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2008",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2008",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-29.png",
            "scheme": "https"
        }
    },
    "437": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #437",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "437",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-45.png",
            "scheme": "https"
        }
    },
    "1181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1181",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1181",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-42.png",
            "scheme": "https"
        }
    },
    "1824": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1824",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1824",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-47.png",
            "scheme": "https"
        }
    },
    "1031": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1031",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1031",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-23.png",
            "scheme": "https"
        }
    },
    "1008": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1008",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1008",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-22.png",
            "scheme": "https"
        }
    },
    "856": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #856",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "856",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-33.png",
            "scheme": "https"
        }
    },
    "1406": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1406",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1406",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-2.png",
            "scheme": "https"
        }
    },
    "1481": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1481",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1481",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-19.png",
            "scheme": "https"
        }
    },
    "1891": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1891",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1891",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-14.png",
            "scheme": "https"
        }
    },
    "1486": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1486",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1486",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-17.png",
            "scheme": "https"
        }
    },
    "891": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #891",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "891",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-36.png",
            "scheme": "https"
        }
    },
    "1858": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1858",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1858",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-35.png",
            "scheme": "https"
        }
    },
    "1545": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1545",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1545",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-34.png",
            "scheme": "https"
        }
    },
    "1304": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1304",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1304",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-35.png",
            "scheme": "https"
        }
    },
    "1746": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1746",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1746",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-42.png",
            "scheme": "https"
        }
    },
    "987": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #987",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "987",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-21.png",
            "scheme": "https"
        }
    },
    "622": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #622",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "622",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-39.png",
            "scheme": "https"
        }
    },
    "1093": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1093",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1093",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-6.png",
            "scheme": "https"
        }
    },
    "247": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #247",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "247",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-36.png",
            "scheme": "https"
        }
    },
    "1444": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1444",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1444",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-45.png",
            "scheme": "https"
        }
    },
    "824": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #824",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "824",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-33.png",
            "scheme": "https"
        }
    },
    "866": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #866",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "866",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-23.png",
            "scheme": "https"
        }
    },
    "1450": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1450",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1450",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-28.png",
            "scheme": "https"
        }
    },
    "2176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2176",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "2176",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-24.png",
            "scheme": "https"
        }
    },
    "1638": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1638",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1638",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-32.png",
            "scheme": "https"
        }
    },
    "1193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1193",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1193",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-38.png",
            "scheme": "https"
        }
    },
    "830": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #830",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "830",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-26.png",
            "scheme": "https"
        }
    },
    "1438": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1438",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1438",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-4.png",
            "scheme": "https"
        }
    },
    "488": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #488",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "488",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-41.png",
            "scheme": "https"
        }
    },
    "16": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #16",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "16",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-20.png",
            "scheme": "https"
        }
    },
    "1248": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1248",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1248",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-24.png",
            "scheme": "https"
        }
    },
    "2195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2195",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2195",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-43.png",
            "scheme": "https"
        }
    },
    "855": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #855",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "855",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-24.png",
            "scheme": "https"
        }
    },
    "2079": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2079",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "2079",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-32.png",
            "scheme": "https"
        }
    },
    "1720": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1720",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1720",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-34.png",
            "scheme": "https"
        }
    },
    "395": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #395",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "395",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-33.png",
            "scheme": "https"
        }
    },
    "1108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1108",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1108",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-2.png",
            "scheme": "https"
        }
    },
    "168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #168",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "168",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-10.png",
            "scheme": "https"
        }
    },
    "1695": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1695",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1695",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-15.png",
            "scheme": "https"
        }
    },
    "1725": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1725",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1725",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-40.png",
            "scheme": "https"
        }
    },
    "1432": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1432",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1432",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-22.png",
            "scheme": "https"
        }
    },
    "545": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #545",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "545",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-27.png",
            "scheme": "https"
        }
    },
    "883": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #883",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "883",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-28.png",
            "scheme": "https"
        }
    },
    "670": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #670",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "670",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-7.png",
            "scheme": "https"
        }
    },
    "1010": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1010",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1010",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-19.png",
            "scheme": "https"
        }
    },
    "992": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #992",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "992",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-15.png",
            "scheme": "https"
        }
    },
    "2053": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2053",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "2053",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-23.png",
            "scheme": "https"
        }
    },
    "966": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #966",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "966",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-38.png",
            "scheme": "https"
        }
    },
    "2110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2110",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2110",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-13.png",
            "scheme": "https"
        }
    },
    "1360": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1360",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1360",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-28.png",
            "scheme": "https"
        }
    },
    "2033": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2033",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "2033",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-35.png",
            "scheme": "https"
        }
    },
    "1979": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1979",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1979",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-33.png",
            "scheme": "https"
        }
    },
    "1305": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1305",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1305",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-40.png",
            "scheme": "https"
        }
    },
    "219": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #219",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "219",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-6.png",
            "scheme": "https"
        }
    },
    "1809": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1809",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1809",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-47.png",
            "scheme": "https"
        }
    },
    "706": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #706",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "706",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-25.png",
            "scheme": "https"
        }
    },
    "1001": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1001",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1001",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-25.png",
            "scheme": "https"
        }
    },
    "301": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #301",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "301",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-18.png",
            "scheme": "https"
        }
    },
    "1559": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1559",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1559",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-2.png",
            "scheme": "https"
        }
    },
    "929": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #929",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "929",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-10.png",
            "scheme": "https"
        }
    },
    "1285": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1285",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1285",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-34.png",
            "scheme": "https"
        }
    },
    "2004": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2004",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2004",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-46.png",
            "scheme": "https"
        }
    },
    "833": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #833",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "833",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-46.png",
            "scheme": "https"
        }
    },
    "1588": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1588",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1588",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-38.png",
            "scheme": "https"
        }
    },
    "104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #104",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "104",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-22.png",
            "scheme": "https"
        }
    },
    "994": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #994",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "994",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-4.png",
            "scheme": "https"
        }
    },
    "1441": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1441",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1441",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-14.png",
            "scheme": "https"
        }
    },
    "651": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #651",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "651",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-16.png",
            "scheme": "https"
        }
    },
    "1244": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1244",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1244",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-9.png",
            "scheme": "https"
        }
    },
    "55": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #55",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "55",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-26.png",
            "scheme": "https"
        }
    },
    "2071": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2071",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "2071",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-16.png",
            "scheme": "https"
        }
    },
    "596": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #596",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "596",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-37.png",
            "scheme": "https"
        }
    },
    "374": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #374",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "374",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-43.png",
            "scheme": "https"
        }
    },
    "2048": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2048",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2048",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-31.png",
            "scheme": "https"
        }
    },
    "2203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2203",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "2203",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-35.png",
            "scheme": "https"
        }
    },
    "1874": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1874",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1874",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-4.png",
            "scheme": "https"
        }
    },
    "1676": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1676",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1676",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-7.png",
            "scheme": "https"
        }
    },
    "566": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #566",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "566",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-28.png",
            "scheme": "https"
        }
    },
    "123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #123",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "123",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-17.png",
            "scheme": "https"
        }
    },
    "735": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #735",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "735",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-26.png",
            "scheme": "https"
        }
    },
    "582": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #582",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "582",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-47.png",
            "scheme": "https"
        }
    },
    "1103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1103",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1103",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-35.png",
            "scheme": "https"
        }
    },
    "447": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #447",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "447",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-40.png",
            "scheme": "https"
        }
    },
    "1614": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1614",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1614",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-7.png",
            "scheme": "https"
        }
    },
    "675": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #675",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "675",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-33.png",
            "scheme": "https"
        }
    },
    "480": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #480",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "480",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-13.png",
            "scheme": "https"
        }
    },
    "1759": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1759",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1759",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-11.png",
            "scheme": "https"
        }
    },
    "1735": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1735",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1735",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-24.png",
            "scheme": "https"
        }
    },
    "221": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #221",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "221",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-10.png",
            "scheme": "https"
        }
    },
    "632": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #632",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "632",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-43.png",
            "scheme": "https"
        }
    },
    "2076": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2076",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "2076",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-7.png",
            "scheme": "https"
        }
    },
    "1187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1187",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1187",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-2.png",
            "scheme": "https"
        }
    },
    "1935": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1935",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1935",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-42.png",
            "scheme": "https"
        }
    },
    "923": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #923",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "923",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-15.png",
            "scheme": "https"
        }
    },
    "1158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1158",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1158",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-14.png",
            "scheme": "https"
        }
    },
    "869": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #869",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "869",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-5.png",
            "scheme": "https"
        }
    },
    "333": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #333",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "333",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-32.png",
            "scheme": "https"
        }
    },
    "210": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #210",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "210",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-26.png",
            "scheme": "https"
        }
    },
    "1002": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1002",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1002",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-19.png",
            "scheme": "https"
        }
    },
    "1370": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1370",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1370",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-43.png",
            "scheme": "https"
        }
    },
    "141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #141",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "141",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-24.png",
            "scheme": "https"
        }
    },
    "1328": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1328",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1328",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-36.png",
            "scheme": "https"
        }
    },
    "1909": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1909",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1909",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-6.png",
            "scheme": "https"
        }
    },
    "183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #183",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "183",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-23.png",
            "scheme": "https"
        }
    },
    "1742": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1742",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1742",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-12.png",
            "scheme": "https"
        }
    },
    "1021": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1021",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1021",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-8.png",
            "scheme": "https"
        }
    },
    "1863": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1863",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1863",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-13.png",
            "scheme": "https"
        }
    },
    "2181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2181",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "2181",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-38.png",
            "scheme": "https"
        }
    },
    "1596": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1596",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1596",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-41.png",
            "scheme": "https"
        }
    },
    "1717": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1717",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1717",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-10.png",
            "scheme": "https"
        }
    },
    "704": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #704",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "704",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-9.png",
            "scheme": "https"
        }
    },
    "1942": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1942",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1942",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-5.png",
            "scheme": "https"
        }
    },
    "2183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2183",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "2183",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-47.png",
            "scheme": "https"
        }
    },
    "1418": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1418",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1418",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-2.png",
            "scheme": "https"
        }
    },
    "1898": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1898",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1898",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-2.png",
            "scheme": "https"
        }
    },
    "1543": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1543",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1543",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-28.png",
            "scheme": "https"
        }
    },
    "1355": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1355",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "1355",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-8.png",
            "scheme": "https"
        }
    },
    "1163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1163",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1163",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-6.png",
            "scheme": "https"
        }
    },
    "540": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #540",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "540",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-15.png",
            "scheme": "https"
        }
    },
    "242": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #242",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "242",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-40.png",
            "scheme": "https"
        }
    },
    "1881": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1881",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1881",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-39.png",
            "scheme": "https"
        }
    },
    "305": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #305",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "305",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-27.png",
            "scheme": "https"
        }
    },
    "2091": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2091",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "2091",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-38.png",
            "scheme": "https"
        }
    },
    "1870": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1870",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1870",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-37.png",
            "scheme": "https"
        }
    },
    "1018": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1018",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1018",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-24.png",
            "scheme": "https"
        }
    },
    "1088": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1088",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1088",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-40.png",
            "scheme": "https"
        }
    },
    "2116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2116",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "2116",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-6.png",
            "scheme": "https"
        }
    },
    "339": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #339",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "339",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-27.png",
            "scheme": "https"
        }
    },
    "2174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2174",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2174",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-14.png",
            "scheme": "https"
        }
    },
    "748": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #748",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "748",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-1.png",
            "scheme": "https"
        }
    },
    "597": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #597",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "597",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-3.png",
            "scheme": "https"
        }
    },
    "336": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #336",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "336",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-42.png",
            "scheme": "https"
        }
    },
    "1462": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1462",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1462",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-37.png",
            "scheme": "https"
        }
    },
    "45": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #45",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "45",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-38.png",
            "scheme": "https"
        }
    },
    "310": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #310",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "310",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-9.png",
            "scheme": "https"
        }
    },
    "1274": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1274",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "1274",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-1.png",
            "scheme": "https"
        }
    },
    "1977": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1977",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1977",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-46.png",
            "scheme": "https"
        }
    },
    "1589": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1589",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1589",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-3.png",
            "scheme": "https"
        }
    },
    "975": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #975",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "975",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-21.png",
            "scheme": "https"
        }
    },
    "1381": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1381",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1381",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-25.png",
            "scheme": "https"
        }
    },
    "1967": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1967",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1967",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-3.png",
            "scheme": "https"
        }
    },
    "131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #131",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "131",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-45.png",
            "scheme": "https"
        }
    },
    "1152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1152",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1152",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-39.png",
            "scheme": "https"
        }
    },
    "279": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #279",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "279",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-28.png",
            "scheme": "https"
        }
    },
    "405": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #405",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "405",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-40.png",
            "scheme": "https"
        }
    },
    "1410": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1410",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1410",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-3.png",
            "scheme": "https"
        }
    },
    "282": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #282",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "282",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-10.png",
            "scheme": "https"
        }
    },
    "1128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1128",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1128",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-44.png",
            "scheme": "https"
        }
    },
    "2077": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2077",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "2077",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-35.png",
            "scheme": "https"
        }
    },
    "1344": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1344",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1344",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-38.png",
            "scheme": "https"
        }
    },
    "1281": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1281",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1281",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-46.png",
            "scheme": "https"
        }
    },
    "1733": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1733",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1733",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-7.png",
            "scheme": "https"
        }
    },
    "1844": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1844",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1844",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-31.png",
            "scheme": "https"
        }
    },
    "2009": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2009",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "2009",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-32.png",
            "scheme": "https"
        }
    },
    "572": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #572",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "572",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-25.png",
            "scheme": "https"
        }
    },
    "1538": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1538",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1538",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-34.png",
            "scheme": "https"
        }
    },
    "1106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1106",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1106",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-8.png",
            "scheme": "https"
        }
    },
    "57": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #57",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "57",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-46.png",
            "scheme": "https"
        }
    },
    "776": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #776",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "776",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-36.png",
            "scheme": "https"
        }
    },
    "448": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #448",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "448",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-43.png",
            "scheme": "https"
        }
    },
    "493": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #493",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "493",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-18.png",
            "scheme": "https"
        }
    },
    "673": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #673",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "673",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-33.png",
            "scheme": "https"
        }
    },
    "230": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #230",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "230",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-4.png",
            "scheme": "https"
        }
    },
    "613": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #613",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "613",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-24.png",
            "scheme": "https"
        }
    },
    "1009": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1009",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1009",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-1.png",
            "scheme": "https"
        }
    },
    "1032": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1032",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1032",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-44.png",
            "scheme": "https"
        }
    },
    "530": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #530",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "530",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-19.png",
            "scheme": "https"
        }
    },
    "474": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #474",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "474",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-38.png",
            "scheme": "https"
        }
    },
    "797": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #797",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "797",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-38.png",
            "scheme": "https"
        }
    },
    "1656": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1656",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1656",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-25.png",
            "scheme": "https"
        }
    },
    "743": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #743",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "743",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-39.png",
            "scheme": "https"
        }
    },
    "600": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #600",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "600",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-29.png",
            "scheme": "https"
        }
    },
    "1042": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1042",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1042",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-30.png",
            "scheme": "https"
        }
    },
    "58": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #58",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "58",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-20.png",
            "scheme": "https"
        }
    },
    "1947": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1947",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1947",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-12.png",
            "scheme": "https"
        }
    },
    "2019": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2019",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "2019",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-16.png",
            "scheme": "https"
        }
    },
    "255": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #255",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "255",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-43.png",
            "scheme": "https"
        }
    },
    "83": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #83",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "83",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-43.png",
            "scheme": "https"
        }
    },
    "2031": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2031",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2031",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-13.png",
            "scheme": "https"
        }
    },
    "1703": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1703",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1703",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-42.png",
            "scheme": "https"
        }
    },
    "649": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #649",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "649",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-22.png",
            "scheme": "https"
        }
    },
    "180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #180",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "180",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-42.png",
            "scheme": "https"
        }
    },
    "1795": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1795",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1795",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-10.png",
            "scheme": "https"
        }
    },
    "1100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1100",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1100",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-43.png",
            "scheme": "https"
        }
    },
    "1470": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1470",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1470",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-7.png",
            "scheme": "https"
        }
    },
    "1238": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1238",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1238",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-8.png",
            "scheme": "https"
        }
    },
    "416": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #416",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "416",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-7.png",
            "scheme": "https"
        }
    },
    "113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #113",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "113",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-13.png",
            "scheme": "https"
        }
    },
    "158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #158",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "158",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-19.png",
            "scheme": "https"
        }
    },
    "329": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #329",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "329",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-43.png",
            "scheme": "https"
        }
    },
    "1924": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1924",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1924",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-41.png",
            "scheme": "https"
        }
    },
    "1051": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1051",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1051",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-12.png",
            "scheme": "https"
        }
    },
    "2112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2112",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2112",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-38.png",
            "scheme": "https"
        }
    },
    "484": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #484",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "484",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-17.png",
            "scheme": "https"
        }
    },
    "1948": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1948",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1948",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-15.png",
            "scheme": "https"
        }
    },
    "1059": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1059",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1059",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-27.png",
            "scheme": "https"
        }
    },
    "995": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #995",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "995",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-30.png",
            "scheme": "https"
        }
    },
    "843": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #843",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "843",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-11.png",
            "scheme": "https"
        }
    },
    "539": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #539",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "539",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-1.png",
            "scheme": "https"
        }
    },
    "144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #144",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "144",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-12.png",
            "scheme": "https"
        }
    },
    "146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #146",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "146",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-29.png",
            "scheme": "https"
        }
    },
    "933": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #933",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "933",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-33.png",
            "scheme": "https"
        }
    },
    "2105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2105",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2105",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-12.png",
            "scheme": "https"
        }
    },
    "881": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #881",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "881",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-8.png",
            "scheme": "https"
        }
    },
    "459": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #459",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "459",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-31.png",
            "scheme": "https"
        }
    },
    "633": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #633",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "633",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-38.png",
            "scheme": "https"
        }
    },
    "655": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #655",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "655",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-36.png",
            "scheme": "https"
        }
    },
    "1413": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1413",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1413",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-28.png",
            "scheme": "https"
        }
    },
    "1046": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1046",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1046",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-34.png",
            "scheme": "https"
        }
    },
    "1892": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1892",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1892",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-18.png",
            "scheme": "https"
        }
    },
    "1502": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1502",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1502",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-45.png",
            "scheme": "https"
        }
    },
    "2083": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2083",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2083",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-1.png",
            "scheme": "https"
        }
    },
    "248": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #248",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "248",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-14.png",
            "scheme": "https"
        }
    },
    "1287": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1287",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1287",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-34.png",
            "scheme": "https"
        }
    },
    "1319": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1319",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1319",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-16.png",
            "scheme": "https"
        }
    },
    "12": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #12",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "12",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-34.png",
            "scheme": "https"
        }
    },
    "2026": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2026",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "2026",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-47.png",
            "scheme": "https"
        }
    },
    "338": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #338",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "338",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-32.png",
            "scheme": "https"
        }
    },
    "1201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1201",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1201",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-47.png",
            "scheme": "https"
        }
    },
    "1033": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1033",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1033",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-39.png",
            "scheme": "https"
        }
    },
    "467": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #467",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "467",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-21.png",
            "scheme": "https"
        }
    },
    "859": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #859",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "859",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-19.png",
            "scheme": "https"
        }
    },
    "638": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #638",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "638",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-30.png",
            "scheme": "https"
        }
    },
    "1417": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1417",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1417",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-30.png",
            "scheme": "https"
        }
    },
    "1327": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1327",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1327",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-30.png",
            "scheme": "https"
        }
    },
    "1398": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1398",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1398",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-4.png",
            "scheme": "https"
        }
    },
    "1708": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1708",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1708",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-38.png",
            "scheme": "https"
        }
    },
    "1923": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1923",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1923",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-3.png",
            "scheme": "https"
        }
    },
    "1833": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1833",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1833",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-41.png",
            "scheme": "https"
        }
    },
    "838": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #838",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "838",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-10.png",
            "scheme": "https"
        }
    },
    "1333": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1333",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1333",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-10.png",
            "scheme": "https"
        }
    },
    "616": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #616",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "616",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-14.png",
            "scheme": "https"
        }
    },
    "2067": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2067",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2067",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-19.png",
            "scheme": "https"
        }
    },
    "306": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #306",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "306",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-31.png",
            "scheme": "https"
        }
    },
    "746": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #746",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "746",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-8.png",
            "scheme": "https"
        }
    },
    "1196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1196",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1196",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-35.png",
            "scheme": "https"
        }
    },
    "1518": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1518",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1518",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-7.png",
            "scheme": "https"
        }
    },
    "1496": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1496",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1496",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-8.png",
            "scheme": "https"
        }
    },
    "2162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2162",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "2162",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-47.png",
            "scheme": "https"
        }
    },
    "1474": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1474",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1474",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-31.png",
            "scheme": "https"
        }
    },
    "178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #178",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "178",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-6.png",
            "scheme": "https"
        }
    },
    "910": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #910",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "910",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-41.png",
            "scheme": "https"
        }
    },
    "1214": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1214",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1214",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-26.png",
            "scheme": "https"
        }
    },
    "1918": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1918",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1918",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-5.png",
            "scheme": "https"
        }
    },
    "1691": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1691",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1691",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-47.png",
            "scheme": "https"
        }
    },
    "657": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #657",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "657",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-7.png",
            "scheme": "https"
        }
    },
    "1070": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1070",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1070",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-5.png",
            "scheme": "https"
        }
    },
    "1674": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1674",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1674",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-44.png",
            "scheme": "https"
        }
    },
    "1781": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1781",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1781",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-46.png",
            "scheme": "https"
        }
    },
    "1681": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1681",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1681",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-10.png",
            "scheme": "https"
        }
    },
    "1458": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1458",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1458",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-9.png",
            "scheme": "https"
        }
    },
    "1168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1168",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1168",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-1.png",
            "scheme": "https"
        }
    },
    "807": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #807",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "807",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-22.png",
            "scheme": "https"
        }
    },
    "78": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #78",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "78",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-33.png",
            "scheme": "https"
        }
    },
    "1776": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1776",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1776",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-29.png",
            "scheme": "https"
        }
    },
    "417": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #417",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "417",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-3.png",
            "scheme": "https"
        }
    },
    "453": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #453",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "453",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-21.png",
            "scheme": "https"
        }
    },
    "261": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #261",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "261",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-22.png",
            "scheme": "https"
        }
    },
    "999": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #999",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "999",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-39.png",
            "scheme": "https"
        }
    },
    "2093": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2093",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "2093",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-31.png",
            "scheme": "https"
        }
    },
    "260": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #260",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "260",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-38.png",
            "scheme": "https"
        }
    },
    "1408": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1408",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1408",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-31.png",
            "scheme": "https"
        }
    },
    "688": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #688",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "688",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-13.png",
            "scheme": "https"
        }
    },
    "1145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1145",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1145",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-30.png",
            "scheme": "https"
        }
    },
    "1569": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1569",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1569",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-26.png",
            "scheme": "https"
        }
    },
    "1127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1127",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1127",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-21.png",
            "scheme": "https"
        }
    },
    "1607": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1607",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1607",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-41.png",
            "scheme": "https"
        }
    },
    "500": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #500",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "500",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-4.png",
            "scheme": "https"
        }
    },
    "2049": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2049",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2049",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-43.png",
            "scheme": "https"
        }
    },
    "1255": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1255",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1255",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-46.png",
            "scheme": "https"
        }
    },
    "408": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #408",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "408",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-36.png",
            "scheme": "https"
        }
    },
    "1968": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1968",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1968",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-33.png",
            "scheme": "https"
        }
    },
    "1316": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1316",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1316",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-27.png",
            "scheme": "https"
        }
    },
    "303": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #303",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "303",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-25.png",
            "scheme": "https"
        }
    },
    "592": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #592",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "592",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-23.png",
            "scheme": "https"
        }
    },
    "913": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #913",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "913",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-37.png",
            "scheme": "https"
        }
    },
    "1405": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1405",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1405",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-21.png",
            "scheme": "https"
        }
    },
    "482": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #482",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "482",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-35.png",
            "scheme": "https"
        }
    },
    "1227": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1227",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1227",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-14.png",
            "scheme": "https"
        }
    },
    "812": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #812",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "812",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-30.png",
            "scheme": "https"
        }
    },
    "1722": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1722",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "1722",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-47.png",
            "scheme": "https"
        }
    },
    "1595": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1595",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1595",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-42.png",
            "scheme": "https"
        }
    },
    "2090": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2090",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2090",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-28.png",
            "scheme": "https"
        }
    },
    "892": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #892",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "892",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-33.png",
            "scheme": "https"
        }
    },
    "936": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #936",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "936",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-26.png",
            "scheme": "https"
        }
    },
    "875": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #875",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "875",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-44.png",
            "scheme": "https"
        }
    },
    "1249": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1249",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1249",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-2.png",
            "scheme": "https"
        }
    },
    "791": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #791",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "791",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-19.png",
            "scheme": "https"
        }
    },
    "624": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #624",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "624",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-29.png",
            "scheme": "https"
        }
    },
    "1756": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1756",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1756",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-14.png",
            "scheme": "https"
        }
    },
    "475": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #475",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "475",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-38.png",
            "scheme": "https"
        }
    },
    "1020": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1020",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1020",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-39.png",
            "scheme": "https"
        }
    },
    "965": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #965",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "965",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-41.png",
            "scheme": "https"
        }
    },
    "1752": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1752",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1752",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-6.png",
            "scheme": "https"
        }
    },
    "1388": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1388",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "1388",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-42.png",
            "scheme": "https"
        }
    },
    "1919": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1919",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1919",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-1.png",
            "scheme": "https"
        }
    },
    "520": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #520",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "520",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-11.png",
            "scheme": "https"
        }
    },
    "556": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #556",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "556",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-45.png",
            "scheme": "https"
        }
    },
    "184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #184",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "184",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-8.png",
            "scheme": "https"
        }
    },
    "2000": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2000",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "2000",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-11.png",
            "scheme": "https"
        }
    },
    "129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #129",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "129",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-27.png",
            "scheme": "https"
        }
    },
    "1637": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1637",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1637",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-10.png",
            "scheme": "https"
        }
    },
    "1655": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1655",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1655",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-40.png",
            "scheme": "https"
        }
    },
    "433": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #433",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "433",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-35.png",
            "scheme": "https"
        }
    },
    "887": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #887",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "887",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-45.png",
            "scheme": "https"
        }
    },
    "257": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #257",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "257",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-16.png",
            "scheme": "https"
        }
    },
    "1761": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1761",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1761",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-1.png",
            "scheme": "https"
        }
    },
    "1551": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1551",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1551",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-13.png",
            "scheme": "https"
        }
    },
    "48": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #48",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "48",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-44.png",
            "scheme": "https"
        }
    },
    "549": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #549",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "549",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-19.png",
            "scheme": "https"
        }
    },
    "2012": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2012",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2012",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-37.png",
            "scheme": "https"
        }
    },
    "1170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1170",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1170",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-21.png",
            "scheme": "https"
        }
    },
    "668": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #668",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "668",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-46.png",
            "scheme": "https"
        }
    },
    "151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #151",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "151",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-40.png",
            "scheme": "https"
        }
    },
    "817": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #817",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "817",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-41.png",
            "scheme": "https"
        }
    },
    "1900": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1900",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1900",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-3.png",
            "scheme": "https"
        }
    },
    "2135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2135",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2135",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-11.png",
            "scheme": "https"
        }
    },
    "1956": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1956",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1956",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-41.png",
            "scheme": "https"
        }
    },
    "854": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #854",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "854",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-29.png",
            "scheme": "https"
        }
    },
    "43": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #43",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "43",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-17.png",
            "scheme": "https"
        }
    },
    "1631": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1631",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1631",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-4.png",
            "scheme": "https"
        }
    },
    "653": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #653",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "653",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-1.png",
            "scheme": "https"
        }
    },
    "473": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #473",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "473",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-34.png",
            "scheme": "https"
        }
    },
    "51": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #51",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "51",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-20.png",
            "scheme": "https"
        }
    },
    "1382": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1382",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1382",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-3.png",
            "scheme": "https"
        }
    },
    "608": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #608",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "608",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-23.png",
            "scheme": "https"
        }
    },
    "986": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #986",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "986",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-25.png",
            "scheme": "https"
        }
    },
    "847": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #847",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "847",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-10.png",
            "scheme": "https"
        }
    },
    "960": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #960",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "960",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-21.png",
            "scheme": "https"
        }
    },
    "1686": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1686",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "1686",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-12.png",
            "scheme": "https"
        }
    },
    "1580": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1580",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1580",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-17.png",
            "scheme": "https"
        }
    },
    "160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #160",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "160",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-30.png",
            "scheme": "https"
        }
    },
    "1826": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1826",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1826",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-21.png",
            "scheme": "https"
        }
    },
    "1583": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1583",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1583",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-6.png",
            "scheme": "https"
        }
    },
    "2": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-38.png",
            "scheme": "https"
        }
    },
    "278": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #278",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "278",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-41.png",
            "scheme": "https"
        }
    },
    "918": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #918",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "918",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-31.png",
            "scheme": "https"
        }
    },
    "1036": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1036",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1036",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-46.png",
            "scheme": "https"
        }
    },
    "626": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #626",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "626",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-39.png",
            "scheme": "https"
        }
    },
    "77": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #77",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "77",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-7.png",
            "scheme": "https"
        }
    },
    "1092": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1092",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1092",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-25.png",
            "scheme": "https"
        }
    },
    "1362": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1362",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1362",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-24.png",
            "scheme": "https"
        }
    },
    "515": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #515",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "515",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-37.png",
            "scheme": "https"
        }
    },
    "396": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #396",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "396",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-30.png",
            "scheme": "https"
        }
    },
    "1696": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1696",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1696",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-6.png",
            "scheme": "https"
        }
    },
    "1260": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1260",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1260",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-24.png",
            "scheme": "https"
        }
    },
    "1014": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1014",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1014",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-14.png",
            "scheme": "https"
        }
    },
    "601": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #601",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "601",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-45.png",
            "scheme": "https"
        }
    },
    "1147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1147",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1147",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-8.png",
            "scheme": "https"
        }
    },
    "1320": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1320",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1320",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-3.png",
            "scheme": "https"
        }
    },
    "476": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #476",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "476",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-24.png",
            "scheme": "https"
        }
    },
    "2136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2136",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "2136",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-3.png",
            "scheme": "https"
        }
    },
    "126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #126",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "126",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-15.png",
            "scheme": "https"
        }
    },
    "1864": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1864",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kenshin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard Fountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#10",
                        "score": 47.0
                    }
                ],
                "id": "1864",
                "rarity_score": 167.99586528928634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/10-30.png",
            "scheme": "https"
        }
    },
    "19": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #19",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "19",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-6.png",
            "scheme": "https"
        }
    },
    "2002": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2002",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "2002",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-7.png",
            "scheme": "https"
        }
    },
    "1266": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1266",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1266",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-11.png",
            "scheme": "https"
        }
    },
    "705": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #705",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "705",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-45.png",
            "scheme": "https"
        }
    },
    "1356": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1356",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1356",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-41.png",
            "scheme": "https"
        }
    },
    "1741": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1741",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1741",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-35.png",
            "scheme": "https"
        }
    },
    "531": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #531",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "531",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-19.png",
            "scheme": "https"
        }
    },
    "1188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1188",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1188",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-43.png",
            "scheme": "https"
        }
    },
    "1030": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1030",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1030",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-35.png",
            "scheme": "https"
        }
    },
    "1015": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1015",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "1015",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-6.png",
            "scheme": "https"
        }
    },
    "832": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #832",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "832",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-25.png",
            "scheme": "https"
        }
    },
    "552": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #552",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "552",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-37.png",
            "scheme": "https"
        }
    },
    "297": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #297",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "297",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-8.png",
            "scheme": "https"
        }
    },
    "701": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #701",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "701",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-28.png",
            "scheme": "https"
        }
    },
    "1173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1173",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1173",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-18.png",
            "scheme": "https"
        }
    },
    "1367": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1367",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "1367",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-13.png",
            "scheme": "https"
        }
    },
    "971": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #971",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "971",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-45.png",
            "scheme": "https"
        }
    },
    "1523": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1523",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1523",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-15.png",
            "scheme": "https"
        }
    },
    "1399": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1399",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1399",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-41.png",
            "scheme": "https"
        }
    },
    "958": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #958",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "958",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-25.png",
            "scheme": "https"
        }
    },
    "2081": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2081",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "2081",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-40.png",
            "scheme": "https"
        }
    },
    "1629": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1629",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1629",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-12.png",
            "scheme": "https"
        }
    },
    "1194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1194",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1194",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-39.png",
            "scheme": "https"
        }
    },
    "1634": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1634",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1634",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-8.png",
            "scheme": "https"
        }
    },
    "1622": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1622",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1622",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-5.png",
            "scheme": "https"
        }
    },
    "1692": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1692",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1692",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-47.png",
            "scheme": "https"
        }
    },
    "1403": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1403",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1403",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-38.png",
            "scheme": "https"
        }
    },
    "407": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #407",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "407",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-18.png",
            "scheme": "https"
        }
    },
    "692": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #692",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Watanabe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Wildflower Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#41",
                        "score": 47.0
                    }
                ],
                "id": "692",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/41-34.png",
            "scheme": "https"
        }
    },
    "1601": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1601",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1601",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-14.png",
            "scheme": "https"
        }
    },
    "1263": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1263",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1263",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-16.png",
            "scheme": "https"
        }
    },
    "872": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #872",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "872",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-18.png",
            "scheme": "https"
        }
    },
    "1574": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1574",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1574",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-31.png",
            "scheme": "https"
        }
    },
    "1930": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1930",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1930",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-22.png",
            "scheme": "https"
        }
    },
    "259": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #259",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "259",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-28.png",
            "scheme": "https"
        }
    },
    "750": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #750",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "750",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-37.png",
            "scheme": "https"
        }
    },
    "1749": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1749",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1749",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-46.png",
            "scheme": "https"
        }
    },
    "1292": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1292",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1292",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-5.png",
            "scheme": "https"
        }
    },
    "2123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2123",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "2123",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-30.png",
            "scheme": "https"
        }
    },
    "610": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #610",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "610",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-22.png",
            "scheme": "https"
        }
    },
    "1848": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1848",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1848",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-11.png",
            "scheme": "https"
        }
    },
    "2106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2106",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "2106",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-2.png",
            "scheme": "https"
        }
    },
    "1312": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1312",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1312",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-25.png",
            "scheme": "https"
        }
    },
    "926": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #926",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "926",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-25.png",
            "scheme": "https"
        }
    },
    "2059": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2059",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "2059",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-24.png",
            "scheme": "https"
        }
    },
    "1827": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1827",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1827",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-5.png",
            "scheme": "https"
        }
    },
    "1984": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1984",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1984",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-39.png",
            "scheme": "https"
        }
    },
    "1324": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1324",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hideyoshi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#9",
                        "score": 47.0
                    }
                ],
                "id": "1324",
                "rarity_score": 154.41782666124772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/9-33.png",
            "scheme": "https"
        }
    },
    "10": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #10",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "10",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-11.png",
            "scheme": "https"
        }
    },
    "614": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #614",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "614",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-17.png",
            "scheme": "https"
        }
    },
    "1754": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1754",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1754",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-4.png",
            "scheme": "https"
        }
    },
    "2192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2192",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2192",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-10.png",
            "scheme": "https"
        }
    },
    "438": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #438",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "438",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-28.png",
            "scheme": "https"
        }
    },
    "932": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #932",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "932",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-37.png",
            "scheme": "https"
        }
    },
    "1202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1202",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1202",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-30.png",
            "scheme": "https"
        }
    },
    "60": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #60",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "60",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-7.png",
            "scheme": "https"
        }
    },
    "991": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #991",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "991",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-11.png",
            "scheme": "https"
        }
    },
    "1504": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1504",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1504",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-25.png",
            "scheme": "https"
        }
    },
    "46": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #46",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "46",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-42.png",
            "scheme": "https"
        }
    },
    "1995": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1995",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1995",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-44.png",
            "scheme": "https"
        }
    },
    "1491": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1491",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1491",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-24.png",
            "scheme": "https"
        }
    },
    "316": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #316",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "316",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-12.png",
            "scheme": "https"
        }
    },
    "2197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2197",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "2197",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-19.png",
            "scheme": "https"
        }
    },
    "1992": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1992",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1992",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-30.png",
            "scheme": "https"
        }
    },
    "1879": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1879",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "1879",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-41.png",
            "scheme": "https"
        }
    },
    "578": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #578",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "578",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-46.png",
            "scheme": "https"
        }
    },
    "2082": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2082",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "2082",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-7.png",
            "scheme": "https"
        }
    },
    "2064": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2064",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "2064",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-47.png",
            "scheme": "https"
        }
    },
    "2085": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2085",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "2085",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-7.png",
            "scheme": "https"
        }
    },
    "176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #176",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "176",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-12.png",
            "scheme": "https"
        }
    },
    "133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #133",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tadahisa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Tekko",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Foggy Mountain",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#47",
                        "score": 47.0
                    }
                ],
                "id": "133",
                "rarity_score": 246.53636363636363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/47-4.png",
            "scheme": "https"
        }
    },
    "1252": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1252",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1252",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-22.png",
            "scheme": "https"
        }
    },
    "801": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #801",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "801",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-16.png",
            "scheme": "https"
        }
    },
    "354": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #354",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "354",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-24.png",
            "scheme": "https"
        }
    },
    "205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #205",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "205",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-7.png",
            "scheme": "https"
        }
    },
    "47": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #47",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "47",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-1.png",
            "scheme": "https"
        }
    },
    "1845": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1845",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "1845",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-11.png",
            "scheme": "https"
        }
    },
    "233": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #233",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "233",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-36.png",
            "scheme": "https"
        }
    },
    "628": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #628",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "628",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-8.png",
            "scheme": "https"
        }
    },
    "2096": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2096",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "2096",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-37.png",
            "scheme": "https"
        }
    },
    "1072": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1072",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Moriyama",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Gunbai",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Village Streets",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#20",
                        "score": 47.0
                    }
                ],
                "id": "1072",
                "rarity_score": 208.33382012724118,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/20-41.png",
            "scheme": "https"
        }
    },
    "904": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #904",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "904",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-12.png",
            "scheme": "https"
        }
    },
    "2185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2185",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "2185",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-22.png",
            "scheme": "https"
        }
    },
    "1359": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1359",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1359",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-33.png",
            "scheme": "https"
        }
    },
    "1706": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1706",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1706",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-20.png",
            "scheme": "https"
        }
    },
    "751": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #751",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "751",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-40.png",
            "scheme": "https"
        }
    },
    "1857": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1857",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1857",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-45.png",
            "scheme": "https"
        }
    },
    "92": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #92",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "92",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-37.png",
            "scheme": "https"
        }
    },
    "1431": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1431",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1431",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-27.png",
            "scheme": "https"
        }
    },
    "1812": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1812",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Musashi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#45",
                        "score": 47.0
                    }
                ],
                "id": "1812",
                "rarity_score": 137.15593022435132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/45-45.png",
            "scheme": "https"
        }
    },
    "1916": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1916",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "1916",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-14.png",
            "scheme": "https"
        }
    },
    "378": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #378",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "378",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-41.png",
            "scheme": "https"
        }
    },
    "967": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #967",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "967",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-46.png",
            "scheme": "https"
        }
    },
    "31": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #31",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "31",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-44.png",
            "scheme": "https"
        }
    },
    "931": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #931",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "931",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-24.png",
            "scheme": "https"
        }
    },
    "155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #155",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "155",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-16.png",
            "scheme": "https"
        }
    },
    "1753": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1753",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1753",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-10.png",
            "scheme": "https"
        }
    },
    "1153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1153",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1153",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-4.png",
            "scheme": "https"
        }
    },
    "863": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #863",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "863",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-23.png",
            "scheme": "https"
        }
    },
    "802": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #802",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Terukage",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#2",
                        "score": 47.0
                    }
                ],
                "id": "802",
                "rarity_score": 136.93212070054176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/2-9.png",
            "scheme": "https"
        }
    },
    "462": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #462",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "462",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-17.png",
            "scheme": "https"
        }
    },
    "6": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #6",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "6",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-26.png",
            "scheme": "https"
        }
    },
    "296": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #296",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "296",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-8.png",
            "scheme": "https"
        }
    },
    "1169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1169",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minoru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#38",
                        "score": 47.0
                    }
                ],
                "id": "1169",
                "rarity_score": 133.28386895229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/38-46.png",
            "scheme": "https"
        }
    },
    "1687": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1687",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1687",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-10.png",
            "scheme": "https"
        }
    },
    "192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #192",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "192",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-1.png",
            "scheme": "https"
        }
    },
    "915": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #915",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "915",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-25.png",
            "scheme": "https"
        }
    },
    "1386": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1386",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1386",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-17.png",
            "scheme": "https"
        }
    },
    "1053": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1053",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "1053",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-31.png",
            "scheme": "https"
        }
    },
    "1311": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1311",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "1311",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-6.png",
            "scheme": "https"
        }
    },
    "2074": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2074",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "2074",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-9.png",
            "scheme": "https"
        }
    },
    "1715": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1715",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1715",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-41.png",
            "scheme": "https"
        }
    },
    "2073": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2073",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "2073",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-34.png",
            "scheme": "https"
        }
    },
    "1818": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1818",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1818",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-4.png",
            "scheme": "https"
        }
    },
    "173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #173",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "173",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-46.png",
            "scheme": "https"
        }
    },
    "1471": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1471",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "1471",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-44.png",
            "scheme": "https"
        }
    },
    "1630": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1630",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1630",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-44.png",
            "scheme": "https"
        }
    },
    "365": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #365",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "365",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-20.png",
            "scheme": "https"
        }
    },
    "1258": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1258",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1258",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-28.png",
            "scheme": "https"
        }
    },
    "1003": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1003",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1003",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-39.png",
            "scheme": "https"
        }
    },
    "1220": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1220",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1220",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-26.png",
            "scheme": "https"
        }
    },
    "135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #135",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "135",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-44.png",
            "scheme": "https"
        }
    },
    "383": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #383",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "383",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-39.png",
            "scheme": "https"
        }
    },
    "1232": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1232",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "1232",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-5.png",
            "scheme": "https"
        }
    },
    "512": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #512",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "512",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-29.png",
            "scheme": "https"
        }
    },
    "789": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #789",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "789",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-15.png",
            "scheme": "https"
        }
    },
    "1546": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1546",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1546",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-13.png",
            "scheme": "https"
        }
    },
    "1843": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1843",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naru",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Tide Rolling In",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#24",
                        "score": 47.0
                    }
                ],
                "id": "1843",
                "rarity_score": 191.21552175894283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/24-45.png",
            "scheme": "https"
        }
    },
    "974": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #974",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "974",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-44.png",
            "scheme": "https"
        }
    },
    "2001": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2001",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "2001",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-36.png",
            "scheme": "https"
        }
    },
    "212": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #212",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "212",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-23.png",
            "scheme": "https"
        }
    },
    "1540": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1540",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "1540",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-10.png",
            "scheme": "https"
        }
    },
    "1112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1112",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1112",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-17.png",
            "scheme": "https"
        }
    },
    "1958": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1958",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1958",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-43.png",
            "scheme": "https"
        }
    },
    "1477": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1477",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1477",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-20.png",
            "scheme": "https"
        }
    },
    "1250": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1250",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Battlefield",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#29",
                        "score": 47.0
                    }
                ],
                "id": "1250",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/29-29.png",
            "scheme": "https"
        }
    },
    "1828": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1828",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1828",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-42.png",
            "scheme": "https"
        }
    },
    "2126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2126",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "2126",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-21.png",
            "scheme": "https"
        }
    },
    "1644": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1644",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1644",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-24.png",
            "scheme": "https"
        }
    },
    "1139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1139",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "1139",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-47.png",
            "scheme": "https"
        }
    },
    "2152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2152",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "2152",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-24.png",
            "scheme": "https"
        }
    },
    "732": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #732",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "732",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-3.png",
            "scheme": "https"
        }
    },
    "724": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #724",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "724",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-11.png",
            "scheme": "https"
        }
    },
    "516": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #516",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "516",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-37.png",
            "scheme": "https"
        }
    },
    "1804": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1804",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1804",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-17.png",
            "scheme": "https"
        }
    },
    "785": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #785",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "785",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-15.png",
            "scheme": "https"
        }
    },
    "820": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #820",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "820",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-15.png",
            "scheme": "https"
        }
    },
    "511": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #511",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "511",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-30.png",
            "scheme": "https"
        }
    },
    "358": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #358",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Eto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#42",
                        "score": 47.0
                    }
                ],
                "id": "358",
                "rarity_score": 169.5308386492597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/42-37.png",
            "scheme": "https"
        }
    },
    "94": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #94",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hattori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Valley Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#25",
                        "score": 47.0
                    }
                ],
                "id": "94",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/25-22.png",
            "scheme": "https"
        }
    },
    "2015": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2015",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "2015",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-19.png",
            "scheme": "https"
        }
    },
    "1279": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1279",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1279",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-15.png",
            "scheme": "https"
        }
    },
    "317": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #317",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Asakura",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#1",
                        "score": 47.0
                    }
                ],
                "id": "317",
                "rarity_score": 162.48083864925968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/1-34.png",
            "scheme": "https"
        }
    },
    "1549": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1549",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masashige",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Tachi",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#17",
                        "score": 47.0
                    }
                ],
                "id": "1549",
                "rarity_score": 162.60409039751147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/17-34.png",
            "scheme": "https"
        }
    },
    "249": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #249",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masakatsu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Roningasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Poppy Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#6",
                        "score": 47.0
                    }
                ],
                "id": "249",
                "rarity_score": 215.63401485901488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/6-11.png",
            "scheme": "https"
        }
    },
    "835": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #835",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "835",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-10.png",
            "scheme": "https"
        }
    },
    "679": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #679",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "679",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-21.png",
            "scheme": "https"
        }
    },
    "908": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #908",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Repect",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tomoe",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Moonlight Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#13",
                        "score": 47.0
                    }
                ],
                "id": "908",
                "rarity_score": 209.816552934974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/13-42.png",
            "scheme": "https"
        }
    },
    "656": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #656",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yagyu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Cane Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#27",
                        "score": 47.0
                    }
                ],
                "id": "656",
                "rarity_score": 183.6421856356067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/27-9.png",
            "scheme": "https"
        }
    },
    "1121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1121",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1121",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-46.png",
            "scheme": "https"
        }
    },
    "767": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #767",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "767",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-19.png",
            "scheme": "https"
        }
    },
    "1027": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1027",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1027",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-3.png",
            "scheme": "https"
        }
    },
    "1490": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1490",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "1490",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-6.png",
            "scheme": "https"
        }
    },
    "917": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #917",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "917",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-37.png",
            "scheme": "https"
        }
    },
    "1217": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1217",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kiyotsuna & Anjin",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#43",
                        "score": 47.0
                    }
                ],
                "id": "1217",
                "rarity_score": 184.93437790279896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/43-43.png",
            "scheme": "https"
        }
    },
    "848": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #848",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "848",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-8.png",
            "scheme": "https"
        }
    },
    "1532": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1532",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1532",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-17.png",
            "scheme": "https"
        }
    },
    "2113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2113",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akio",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#32",
                        "score": 47.0
                    }
                ],
                "id": "2113",
                "rarity_score": 168.9857920292131,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/32-31.png",
            "scheme": "https"
        }
    },
    "229": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #229",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "229",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-12.png",
            "scheme": "https"
        }
    },
    "644": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #644",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Rinoie",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Fan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Rice Field Sunset",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#7",
                        "score": 47.0
                    }
                ],
                "id": "644",
                "rarity_score": 220.5701754385965,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/7-10.png",
            "scheme": "https"
        }
    },
    "1179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1179",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "1179",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-40.png",
            "scheme": "https"
        }
    },
    "678": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #678",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "678",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-12.png",
            "scheme": "https"
        }
    },
    "20": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #20",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "20",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-27.png",
            "scheme": "https"
        }
    },
    "1269": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1269",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "1269",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-2.png",
            "scheme": "https"
        }
    },
    "898": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #898",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "898",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-4.png",
            "scheme": "https"
        }
    },
    "940": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #940",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Minokan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#36",
                        "score": 47.0
                    }
                ],
                "id": "940",
                "rarity_score": 135.5434843369054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/36-24.png",
            "scheme": "https"
        }
    },
    "235": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #235",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "235",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-10.png",
            "scheme": "https"
        }
    },
    "1647": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1647",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "1647",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-26.png",
            "scheme": "https"
        }
    },
    "390": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #390",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Saigo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Cove",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#30",
                        "score": 47.0
                    }
                ],
                "id": "390",
                "rarity_score": 176.64813801655907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/30-33.png",
            "scheme": "https"
        }
    },
    "1475": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1475",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Shimazu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sunrise Over The Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#35",
                        "score": 47.0
                    }
                ],
                "id": "1475",
                "rarity_score": 175.9594933279144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/35-12.png",
            "scheme": "https"
        }
    },
    "1189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1189",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Toyotomi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#12",
                        "score": 47.0
                    }
                ],
                "id": "1189",
                "rarity_score": 186.5000405000405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/12-30.png",
            "scheme": "https"
        }
    },
    "1443": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1443",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Munenori",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain Lake",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#11",
                        "score": 47.0
                    }
                ],
                "id": "1443",
                "rarity_score": 163.59988626830733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/11-33.png",
            "scheme": "https"
        }
    },
    "978": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #978",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Esumi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Wakizashi",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Yumi Bow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Fishing Village",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#19",
                        "score": 47.0
                    }
                ],
                "id": "978",
                "rarity_score": 194.02018376360482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/19-16.png",
            "scheme": "https"
        }
    },
    "1774": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1774",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akao",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Courtyard",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#22",
                        "score": 47.0
                    }
                ],
                "id": "1774",
                "rarity_score": 166.54814634156742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/22-29.png",
            "scheme": "https"
        }
    },
    "1007": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1007",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Miyamoto",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Scroll",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#3",
                        "score": 47.0
                    }
                ],
                "id": "1007",
                "rarity_score": 157.74582478924586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/3-34.png",
            "scheme": "https"
        }
    },
    "1498": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1498",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "1498",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-15.png",
            "scheme": "https"
        }
    },
    "423": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #423",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Shogun",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tokugawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Face Paint",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Muramasa Sword",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mosochiku Forest",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#16",
                        "score": 47.0
                    }
                ],
                "id": "423",
                "rarity_score": 277.05955598455597,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/16-46.png",
            "scheme": "https"
        }
    },
    "2118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2118",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "2118",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-3.png",
            "scheme": "https"
        }
    },
    "251": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #251",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "251",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-28.png",
            "scheme": "https"
        }
    },
    "1086": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1086",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Akechi",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Benkan",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "None",
                        "score": 11.75
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Castle",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#46",
                        "score": 47.0
                    }
                ],
                "id": "1086",
                "rarity_score": 191.11669884169882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/46-9.png",
            "scheme": "https"
        }
    },
    "110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #110",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Naosmasa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "The Dojo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#26",
                        "score": 47.0
                    }
                ],
                "id": "110",
                "rarity_score": 164.08624157966264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/26-33.png",
            "scheme": "https"
        }
    },
    "386": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #386",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Takeda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Field Nightscape",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#4",
                        "score": 47.0
                    }
                ],
                "id": "386",
                "rarity_score": 177.61654460996567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/4-46.png",
            "scheme": "https"
        }
    },
    "72": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #72",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "72",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-38.png",
            "scheme": "https"
        }
    },
    "1116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1116",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1116",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-39.png",
            "scheme": "https"
        }
    },
    "771": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #771",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hachiro",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Mengu Mask",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room Encounter",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#15",
                        "score": 47.0
                    }
                ],
                "id": "771",
                "rarity_score": 207.76194526194527,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/15-3.png",
            "scheme": "https"
        }
    },
    "968": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #968",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ashikaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#39",
                        "score": 47.0
                    }
                ],
                "id": "968",
                "rarity_score": 135.39284331126436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/39-28.png",
            "scheme": "https"
        }
    },
    "1747": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1747",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Kusunoki",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Do-Maru",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Te-Yari",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Throne Room",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Character",
                        "value": "#18",
                        "score": 47.0
                    }
                ],
                "id": "1747",
                "rarity_score": 184.43997556497555,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/18-37.png",
            "scheme": "https"
        }
    },
    "1539": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1539",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Imagawa",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Sandogasa",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Courtyard",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#37",
                        "score": 47.0
                    }
                ],
                "id": "1539",
                "rarity_score": 158.11496723996726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/37-14.png",
            "scheme": "https"
        }
    },
    "208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #208",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "208",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-43.png",
            "scheme": "https"
        }
    },
    "1815": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1815",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Sincerity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nagahide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "Kabuto Helmet",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Mountain River",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#40",
                        "score": 47.0
                    }
                ],
                "id": "1815",
                "rarity_score": 193.20728325728325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/40-27.png",
            "scheme": "https"
        }
    },
    "2194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #2194",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Sanada",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Tanto",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#33",
                        "score": 47.0
                    }
                ],
                "id": "2194",
                "rarity_score": 165.57973974816082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/33-9.png",
            "scheme": "https"
        }
    },
    "587": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #587",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Heroic Courage",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Nobunaga",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Dual Naginata",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Nightime Docks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#21",
                        "score": 47.0
                    }
                ],
                "id": "587",
                "rarity_score": 187.7094933279144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/21-37.png",
            "scheme": "https"
        }
    },
    "1307": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1307",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "1307",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-14.png",
            "scheme": "https"
        }
    },
    "1797": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1797",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Daimyo",
                        "score": 3.9166666666666665
                    },
                    {
                        "trait_type": "Name",
                        "value": "Masamune",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Momiji Castle",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#8",
                        "score": 47.0
                    }
                ],
                "id": "1797",
                "rarity_score": 165.5065712499923,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/8-21.png",
            "scheme": "https"
        }
    },
    "1906": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1906",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Integrity",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yamagata & Kenji",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Puppy Power",
                        "score": 23.5
                    },
                    {
                        "trait_type": "Background",
                        "value": "Bad Side Of Town",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#5",
                        "score": 47.0
                    }
                ],
                "id": "1906",
                "rarity_score": 188.03327900170007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/5-28.png",
            "scheme": "https"
        }
    },
    "1531": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1531",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Oda",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Nodachi",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "East End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#23",
                        "score": 47.0
                    }
                ],
                "id": "1531",
                "rarity_score": 173.549236917658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/23-5.png",
            "scheme": "https"
        }
    },
    "1183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1183",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Honor",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Tsunari",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "O-Yoroi",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Naginata",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "River Banks",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#28",
                        "score": 47.0
                    }
                ],
                "id": "1183",
                "rarity_score": 209.10051896894004,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/28-20.png",
            "scheme": "https"
        }
    },
    "1440": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1440",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Loyalty",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Hanzo",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Nagagi",
                        "score": 9.4
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Bridge",
                        "score": 15.666666666666666
                    },
                    {
                        "trait_type": "Character",
                        "value": "#44",
                        "score": 47.0
                    }
                ],
                "id": "1440",
                "rarity_score": 141.223237916659,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/44-12.png",
            "scheme": "https"
        }
    },
    "769": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #769",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Ronin",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Name",
                        "value": "Ieyasu",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Haori",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Arrow",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Background",
                        "value": "Starry Starry Night",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#31",
                        "score": 47.0
                    }
                ],
                "id": "769",
                "rarity_score": 217.51314080656186,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/31-18.png",
            "scheme": "https"
        }
    },
    "1513": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1513",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Compassion",
                        "score": 6.714285714285714
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Mitsuhide",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Jinbaori",
                        "score": 5.875
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Daisho",
                        "score": 4.2727272727272725
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "Daisho",
                        "score": 4.7
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "Sakura Tera",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#34",
                        "score": 47.0
                    }
                ],
                "id": "1513",
                "rarity_score": 166.87681767023872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/34-24.png",
            "scheme": "https"
        }
    },
    "1570": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Pit Bull Bushido #1570",
                "description": "The 1st collection in the Islands of Damaya series, these Samurai of Kadu are a tribute to the timeless legacy of the Samurai, with 47 figures representing the seven Virtues of Bushido - integrity, respect, heroic courage, honor, sincerity, compassion and loyalty",
                "external_url": "https://www.islandsofdamaya.com/",
                "attributes": [
                    {
                        "trait_type": "Virtue",
                        "value": "Respect",
                        "score": 7.833333333333333
                    },
                    {
                        "trait_type": "Title",
                        "value": "Samurai",
                        "score": 1.8076923076923077
                    },
                    {
                        "trait_type": "Name",
                        "value": "Yasuke",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Hakama",
                        "score": 3.6153846153846154
                    },
                    {
                        "trait_type": "Headgear",
                        "value": "None",
                        "score": 1.236842105263158
                    },
                    {
                        "trait_type": "Primary Weapon",
                        "value": "Katana",
                        "score": 2.1363636363636362
                    },
                    {
                        "trait_type": "Secondary Weapon",
                        "value": "None",
                        "score": 2.238095238095238
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "None",
                        "score": 1.2702702702702702
                    },
                    {
                        "trait_type": "Background",
                        "value": "West End Village",
                        "score": 47.0
                    },
                    {
                        "trait_type": "Character",
                        "value": "#14",
                        "score": 47.0
                    }
                ],
                "id": "1570",
                "rarity_score": 161.13798150640255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://pit-bull-bushido.nyc3.cdn.digitaloceanspaces.com/14-33.png",
            "scheme": "https"
        }
    }
}

export default pitBullBushidoMetaData
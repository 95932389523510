import React, { useState, useContext } from "react";
import { IoWallet } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import ScreenSize from "../layouts/ScreenSize";
import MoveFundsModal from "../Mint/MoveFundsModal";
import { PactContext } from "../pact/PactContextProvider";
import {
    Box,
    Flex,
    useDisclosure,
    Text,
    Image,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
} from "@chakra-ui/react";
import Balance from "./Balance";
import ConnectWalletModal from "../WalletConnection/ConnectWalletModal";
import { SuccessListingModal } from "../DialogueModals/StatusModals";
import { LOCAL_ACCOUNT_KEY } from "../utils/Constants";
import { tryLoadLocal, useInterval } from "../utils/utils";
import IsokoLogo from "../assets/images/latest/logo.svg";
import { useNavigate } from "react-router-dom";
import AccountDropDown from "./AccountDropDown";
import { ComingSoonComponent } from "./ComingSoonComponent";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import SearchInput from "./SearchInput";

const NavBar = () => {
    const { account, logoutAccount, pollForAccountBalanceDataOnAllChains } =
        useContext(PactContext);
    const { onOpenConnectModal } = useContext(IsokoDialogContext);
    const [showNav, setShowNav] = useState(false);
    const navigate = useNavigate();

    function calculateTotal(chainMapData) {
        let totalChainSum = 0.0;

        chainMapData.forEach((chainAccountData, index) => {
            totalChainSum += chainAccountData.accountData.balance;
        });

        return totalChainSum;
    }

    useInterval(
        async () => {
            let accountLocalStorage = tryLoadLocal(LOCAL_ACCOUNT_KEY);

            if (accountLocalStorage) {
                await pollForAccountBalanceDataOnAllChains(account.account);
                let chainMapData = tryLoadLocal(LOCAL_ACCOUNT_KEY);
                calculateTotal(chainMapData.chainMap);
            }
        },
        20000,
        true
    );

    return (
        <ScreenSize>
            {/* Modal to move Ends */}
            <Box bgColor="transparent">
                {/* <Box bgColor="#0a0a0a"> */}
                <Flex className="navbar latestDesign">
                    <Flex className="" gap="1.25rem" w="100%">
                        <Text
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/")}
                            fontWeight="900"
                        >
                            <Image w="11.25rem" src={IsokoLogo} alt="Logo" />
                        </Text>
                    </Flex>

                    <Flex
                        display={["none", null, "flex"]}
                        align="center"
                        gap={!account ? "32px" : "20px"}
                        className="navbar-right nav_item"
                        w="100%"
                        justifyContent={"flex-end"}
                    // flexShrink={0}
                    >
                        <Text
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/")}
                            fontWeight="900"
                            as="a"
                            href="#collections"
                            flexShrink={0}
                            fontSize="16px"
                        >
                            Marketplace Collections
                        </Text>

                        <Text
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/launchpad")}
                            as="a"
                            href="#collections"
                            fontWeight="900"
                        >
                            Launchpad
                        </Text>

                        {account && (
                            <>
                                <Box mx="0px" flexShrink={0}>
                                    {/* <Balance chainMapData={chainMap} totalBalance={totalBalance} /> */}
                                    <Balance
                                        chainMapData={account?.chainMap}
                                        totalBalance={account?.totalBalance}
                                    />
                                </Box>
                                <Box>
                                    <AccountDropDown />
                                </Box>
                            </>
                        )}
                        {!account ? (
                            <Button
                                id="connect-wallet"
                                rounded="full"
                                bgColor={"#bd3df0"}
                                className="connectWallet"
                                onClick={() => {
                                    onOpenConnectModal();
                                }}
                                _hover={{}}
                                _active={{}}
                                _focus={{}}
                            >
                                <IoWallet />
                                <span style={{ "color": "white" }}>Connect Wallet</span>
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Flex>
                    {showNav ? (
                        ""
                    ) : (
                        <button
                            display={["block", null, "none"]}
                            className="toggler"
                            onClick={() => setShowNav(!showNav)}
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#ffff",
                            }}
                        >
                            <FaBars />
                        </button>
                    )}

                    {showNav && (
                        <Box className="navbar-mobile" bgColor="#160e15">
                            <a href="#collections">Trade NFTs</a>
                            <a href="/launchpad">Launchpad</a>

                            <Box>
                                <Balance
                                    chainMapData={account?.chainMap}
                                    totalBalance={account?.totalBalance}
                                />
                            </Box>

                            <Button
                                mt="24px"
                                _hover={{}}
                                _focus={{}}
                                _active={{}}
                                fontSize={["12px", "14px", "16px"]}
                                onClick={() => setShowNav(false)}
                                bgColor={"#BC3CF0"}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Flex>
            </Box>
        </ScreenSize>
    );
};

export default NavBar;

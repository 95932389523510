import { Box, Icon } from "@chakra-ui/react"
import { FiChevronLeft } from "react-icons/fi"

const Back = ({ callback }) => {
    return (
        <Box py={"20px"} cursor="pointer">
            <Box
                onClick={() => callback()}
                className="backBtn"
                style={{ alignItems: "center" }}
            >
                <Icon
                    as={FiChevronLeft}
                    boxSize={"1.25rem"}
                    display={"inline-block"}
                />
                Back
            </Box>
        </Box>
    );
};

export {
    Back
}